.captcha {
  position: absolute;
  /* text-decoration-line: line-through; */
  top: 50.5%;
  right: 40.9%;
  font-size: 27px;
  letter-spacing: 8px;
  color: #808080;
}
@media screen and (max-width: 600px) and (min-width: 400px) {
  .captcha {
    top: 60%;
    right: 46%;
  }
}

@media screen and (max-width: 1000px) and (min-width: 700px) {
  .captcha {
    top: 52%;
    right: 23%;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1000px) {
  .captcha {
    top: 51%;
    right: 31%;
  }
}

@media screen and (max-width: 2000px) and (min-width: 1400px) {
  .captcha {
    top: 50.7%;
    right: 37%;
  }
}
