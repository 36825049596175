.span-text {
  font-family: 'Roboto';
  letter-spacing: 1px;
  font-size: 14px;
}
.div-data-list {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  width: 31%;
}
.div-data-list-asset-details {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  width: 31%;
  /* border: 1px solid #000; */
}
ul.ul-listing {
  padding-left: 0px !important;
}
ul.ul-listing img {
  width: 110px;
  height: 110px;
  margin-right: 18px;
}

.modal:nth-of-type(even) {
  z-index: 1050 !important;
}
.modal-backdrop.show:nth-of-type(even) {
  z-index: 1050 !important;
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 94%;
  }
}

.revenue-header {
  padding-bottom: 5px;
  border-bottom: 1px solid lightgrey;
}
.asset-details-section {
  height: 100%;
}
/* Edit Modal Start */
.heading-edit-modal {
  padding: 8px 1px 1px 0px;
  margin-bottom: 9px;
  font-weight: 400;
  border-bottom: 2px solid darkgray;
}

.revenue-details {
  box-shadow: 1px solid rebeccapurple !important;
}
/* Edit Modal End */

.mapRow {
  text-align: center;
  height: 100%;
  width: 100%;
}

.map-container {
  height: 100%;
  width: 100%;
}

/* Placeholder */
.datepickerInput::placeholder {
  color: #000;
  opacity: 1; /* Firefox */
}

.datepickerInput:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000;
}

.datepickerInput::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000;
}

.modal-header-section {
  padding-bottom: 10px !important;
  background-color: #e8ecf1 !important;
  border-color: #d7e8fc !important;
}

.modal-header-text {
  font-size: 19px;
}
.sub-header-text {
  font-size: 17px;
  margin-bottom: 5px !important;
}

/* Department Modal Start */
.dept-sec-name {
  width: 70%;
}
/* Department Modal End */

/* Expesnse Modal Start */
/* table */

/* Expesnse Modal End */

/* Add Asset Modal Start */
img.surveyor-image-add-asset {
  height: 100px;
  width: 100px;
  margin-top: 12px;
}
.add-button-modal-add-asset {
  text-align: end;
}
.choose-image-button- {
  text-align: center;
}

.not-selected:hover {
  outline: 2px solid #1b79a5;
}
.selected-section {
  /* outline: 2px solid #cf6b19; */
  /* border-color: #134f9c !important; */
  border: 2px solid #134f9c !important;
}

/* Add Asset Modal End */
/* Add user modal start */
.modal-120w {
  max-width: 53rem;
}
.modal-90w {
  max-width: 45rem;
}

.role-view-container {
  display: flex;
  border-radius: 4px;
  border: 1px solid #ced4da;
  /* height: 90px; */
  width: 97%;
}

.div-user-data {
  font-family: 'Poppins';
  font-size: 14px;
  margin-bottom: 5px;
  width: 39%;
}

img.user-profile-container {
  height: 91px;
  width: 121px;
  /* margin-top: 12px; */
}
/* Add user modal end */

/* Choose image modal start */
.modal-90w-choose-image {
  max-width: 82rem;
  max-height: 59rem;
}
.choose-image-modal .modal-content {
  background-color: #427db9;
  color: white;
}
/* Choose image modal end */

.bottom-right-text {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlay-text-image {
  color: white;
  font: 12px/23px Roboto;
  /* background: rgb(0, 0, 0); */
  background: rgba(0, 0, 0, 0.4);
  /* padding: 2px; */
}

/* Add User start */
.profile-pic-div {
  height: 80px;
  width: 80px;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid grey;
}

#photo {
  height: 100%;
  width: 100%;
}

#file {
  display: none;
}

#uploadBtn {
  height: 30px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  background: rgba(69, 100, 141, 0.7);
  color: #fff;
  line-height: 30px;
  font-family: sans-serif;
  font-size: 12px;
  cursor: pointer;
  display: none;
}
/* Add User end */

/* View asset start */
.view-asset-details-col-align {
  display: table-cell;
}

.expenseFooter {
  color: #fff;
  background-color: #95aac3 !important;
  font-weight: 400;
}
/* View asset end */

.no-data-dispose-section {
  font-size: 18px;
  font-weight: 400;
  color: grey;
}
.title-dispose-header {
  width: 7rem;
}
.colon-dispose-modal {
  width: 2rem;
}
.eye-react-icons-add-user {
  position: absolute;
  /* right: 0.75em; */
  top: 29.5em;
  left: 24rem;
  z-index: 9;
}
.modal-width-dispose {
  width: 60% !important;
}
.modal-width-dispose-preference {
  width: 40% !important;
}
th.expenseFooter {
  border-style: hidden;
}
label.label-dispose-form.form-label {
  font-size: 13px;
}

/* Typehead */
button.close.btn-close.rbt-close {
  font-size: 15px !important;
}
span.rbt-close-content {
  display: none !important;
}
/* Typehead */
.td-bold {
  font-weight: bold !important;
}

/* User view */
.td-user-view {
  padding-bottom: 7px;
  font-weight: 500;
  color: #0c3060;
  width: 2rem;
}
td.semicolon-view-user {
  width: 4rem;
  font-weight: 500;
}
