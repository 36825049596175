.mapRowGis {
  text-align: center;
  width: 100%;
  position: relative;
}

.map-container {
  height: 100%;
  width: 100%;
}
.gis-map-section {
  padding: 5.2px;
  background: #fff;
  border: 1px solid #d5d2d2;
  resize: horizontal;
  transition: 500ms linear;
}

.resizable {
  resize: both;
  overflow: scroll;
}
.division-asset-count {
  /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
  width: 100%;
  border-radius: 12px;
  border: 1px solid #d5d2d2;
  padding-top: 55px;
  padding-left: 35px;
  /* justify-content: center;
  align-items: center; */
  flex-direction: column;
  color: white;
}

.count-header {
  font-size: 18px;
  letter-spacing: 2px;
  font-family: 'Montserrat';
  font-weight: 400;
}

.break {
  border-width: 19px;
  font-size: 12px;
  width: 4rem;
  border-top: 3px solid white;
  padding-top: 15px;
  font-weight: 600;
}
.container-legend-list {
  display: inline-flex;
  border: 1px solid #0079c1;
  color: #0079c1;
  position: absolute;
  top: 8px;
  right: 1rem;
  background-color: #fff;
  cursor: pointer;
}

.compass-section {
  display: inline-flex;
  color: #0079c1;
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.ol-zoom {
  top: 5em !important;
  right: 0.7em !important;
  left: unset !important;
}

.bar-in-control {
  border: 0.8px solid #0079c1;
  height: 18px;
}

.map-type-section {
  display: inline-flex;
  border-radius: 8px;
  color: #0079c1;
  position: absolute;
  bottom: 10px;
  left: 0.7rem;
  background-color: transparent;
  cursor: pointer;
}

.list-item {
  padding: 0.2rem;
}
.list-item-selected {
  border-radius: 2px;
  background-color: #0079c1;
  color: #fff !important;
  padding: 0.2rem;
}
.list-item:hover {
  background-color: #0079c1;
  color: #fff !important;
}

button.ol-zoom-in,
button.ol-zoom-out {
  background: #fff !important;
  border: unset !important;
  color: #000 !important;
  font-size: 23px !important;
  margin-bottom: 5px !important;
  font-weight: 100 !important;
  border-radius: 3px !important;
}
.ol-zoom.ol-unselectable.ol-control {
  background-color: transparent;
}
.ol-full-screen.ol-unselectable.ol-control {
  border: 1px solid #0c1e35 !important;
  background: #fff !important;
  border-radius: 0;
}
button.ol-full-screen-false {
  background: transparent !important;
  color: #000 !important;
  font-size: 17px !important;
}

.fill-window {
  height: 100%;
  position: fixed;
  left: 0;
  top: 10;
  width: 100%;
  overflow: hidden;
}
.boundary-list {
  cursor: pointer;
}
.boundary-list-selected {
  cursor: pointer;
  outline: 2px solid #007ac2;
}
.tooltip-arrow {
  display: none !important;
}
.map-type-text {
  font-family: 'Roboto', sans-serif;
  font-size: 11px !important;
  color: gray;
  letter-spacing: 1px;
}

@media screen and (min-width: 900px) and (max-width: 1400px) {
  .mapRowGis {
    height: 30rem;
  }
  .map-container {
    height: 100%;
    width: 100%;
  }
  .gis-map-section {
    padding: 12px;
    background: #fff;
    border: 1px solid #d5d2d2;
    resize: horizontal;
  }
}

/* popup design starts */
 .card-header-popup {
  margin: 0 !important;
  padding: 5px 0 !important;
  background: #fff !important;
  height: 47px !important;
  border-bottom: solid 1px #6e6e6e4d !important;
}

.popup-action-text {
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  color: #6e6e6e !important;
  white-space: nowrap;
} 

.popup-card-shadow {
  box-shadow: 0 1px 4px rgb(0 0 0 / 80%);
}

.map-popup__inline-actions-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0 7px;
  position: relative;
}

.map_popup__navigation {
  background-color: #eee;
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 15px 0 auto;
  padding: 0;
  justify-content: center;
}

.map-popup-button {
  border-radius: 2px;
  padding: 6px 4px;
  margin: 6px 0;
  line-height: 1.3em;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  color: #6e6e6e;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  transition: background-color 125ms ease-in-out;
}

.map-popup__footer {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 6px 0;
} 
 .map-popup-button:hover {
  color: #2e2e2e;
  background-color: #f3f3f3;
}

.map-popup-header {
  position: relative;
  font-size: 12px;
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
  flex: 0 0 auto;
}
.map-popup-heading-title {
  border-radius: 2px;
  font-size: 16px;
  padding: 6px 7px;
  margin: 6px auto 6px 7px;
  display: block;
  transition: background-color 125ms ease-in-out;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
  word-break: break-word;
}
.popup-header-buttons {
  padding: 0 7px;
  margin: 0 0 0 auto;
  display: flex;
}

.popup__content {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  font-size: 12px;
  font-weight: 400;
  margin: 0 15px 12px;
  height: 15rem;
  overflow: hidden;
  line-height: normal;
}
.popup-widget {
  box-sizing: border-box;
  color: #323232;
  font-size: 14px;
  font-family: 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.3em;
  background-color: #fff;
}
.popup-feature__content-element {
  padding: 0 7px;
  margin-bottom: 24px;
}
.popup-widget__table {
  color: #323232;
  border: none;
  border-collapse: collapse;
  line-height: 1.3em;
  width: 100%;
}
.popup-widget__table tr th {
  width: 50%;
  text-align: left;
  border-right: 2px solid #0000001a;
  border-bottom-width: 0px !important;
}

.popup-widget__table tr td,
.popup-widget__table tr th {
  padding: 0.5em 0.7em;
  word-break: break-word;
  vertical-align: top;
  font-size: 12px;
  font-weight: 400;
  border-bottom-width: 0px !important;
  width: 150px;
}

.popup-widget__table tr:nth-child(odd) {
  --bs-table-accent-bg: #66656512 !important;
  color: var(--bs-table-striped-color);
}

/* TODO: uncomment this code for scrolling table data */
/* .popup-widget__table thead,
tbody {
  display: block;
} */

.popup-widget__table tbody {
  height: 15rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.c1::-webkit-scrollbar {
  width: 5px;
}

.c1::-webkit-scrollbar-track {
  background: rgba(255, 255, 255);
  border-radius: 0px;
  margin: 10px 0;
}
.c1::-webkit-scrollbar-thumb {
  background: #cac9c9;
  border-radius: 0px;
}

.c1::-webkit-scrollbar-thumb:hover {
  background: #cac9c9;
}
.c1::-webkit-scrollbar-track {
  background-color: rgba(238, 236, 236, 0.75);
}

/* popup style start */
/* .ol-popup {
  position: absolute;
  border-radius: 10px;
  bottom: 12px;
  min-width: 360px;
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}
.ol-popup-closer:after {
  content: '✖';
} */
/* popup style end */

@media screen and (max-width: 2000px) and (min-width: 1400px){
 .mapRowGis {
   height: 76vh !important;
 }
}