.progress-bar {
  background-color: #123456 !important;
}
.btn-spinner {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  border: none;
  background-color: #1d3557;
  color: #f1faee;
  padding: 0.8rem 1.5rem;
  border-radius: 2rem;
  cursor: pointer;
  transition: 0.5s ease;
  box-shadow: 0 1rem 2rem -1rem rgba(0, 0, 0, 0.445);
}

.btn-spinner:hover {
  background-color: #003049;
  color: #eae2b7;
  box-shadow: 0 1rem 1rem -1rem rgba(0, 0, 0, 0.445);
}

.btn__text {
  margin-left: 0.8rem;
}

.spinner {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  border: 3px solid #dddf00;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: spin 3s infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
    border-top-color: #fcab41;
    border-bottom-color: #80ffdb;
    border-right-color: transparent;
    border-left-color: transparent;
  }
  100% {
    transform: rotate(360deg);
  }
}