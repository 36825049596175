/* @import 'https://code.highcharts.com/css/highcharts.css'; */

.highcharts-figure,
.highcharts-data-table table {
  min-width: 310px;
  max-width: 800px;
  margin: 1em auto;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

.highcharts-yaxis .highcharts-axis-line {
  stroke-width: 2px;
}

/* Link the series colors to axis colors */
.pc-row .highcharts-color-0 {
  fill: #7cb5ec;
  stroke: #7cb5ec;
}

.pc-row .highcharts-axis.highcharts-color-0 .highcharts-axis-line {
  stroke: #7cb5ec;
} 

.pc-row .highcharts-axis.highcharts-color-0 text {
  fill: #7cb5ec;
}

.pc-row .highcharts-color-1 {
  fill: #90ed7d;
  stroke: #90ed7d;
}

.pc-row .highcharts-axis.highcharts-color-1 .highcharts-axis-line {
  stroke: #90ed7d;
}

.pc-row .highcharts-axis.highcharts-color-1 text {
  fill: #90ed7d;
}
/* .highcharts-background {
  fill: #006ba2;
  stroke: #a4edba;
  stroke-width: 2px;
} */

.highcharts-drilldown-axis-label {
	text-decoration: none !important;
}