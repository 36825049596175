/* html {
  scroll-behavior: smooth;
  min-height: 100vh;
}

body {
  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
} */

.bg-grey {
  background-color: #eeeeee;
}

a {
  text-decoration: none;
  color: black;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #ced4da !important;
  outline: 0;
  box-shadow: 0 0 0 0rem rgb(13 110 253 / 25%) !important;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: none !important;
  border-color: none !important;
}

/* Number field arrow hide */
/* For chrome */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Custom scrollbar start */
::-webkit-scrollbar {
  width: 8px;
  height: 5px;
  padding-right: 2px;
  border-radius: 29px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 29px;
  padding-right: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 29px;
  padding-right: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* Custom scrollbar  end */

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}
