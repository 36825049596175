.app main header {
  margin-bottom: 15px !important;
}
.app main {
  padding: 0px 0px 0px 2px !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #28436a !important;
  border-color: #28436a !important;
}
.page-link {
  color: #0c1e35 !important;
}
.accordion-button:not(.collapsed),
button.accordion-button.collapsed {
  /* color: #2d63a7 !important;
  font-weight: 600 !important;
  font-size: 19px !important;
  background-color: #e6eaef !important;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%) !important; */
  color: #2d63a7 !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  font-size: 19px !important;
  border: 1px solid #0000 !important;
  background-color: #b3c1cf26 !important;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%) !important;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #d8dfe8 !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 25%);
}
span#search-bar-0-label {
  display: none !important;
}

.form-select:focus {
  border-color: unset !important;
  box-shadow: unset !important;
}

.dropdown-menu.show {
  margin-top: 6px !important;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  /* background-color: transparent !important; */
  box-shadow: none !important;
  border-color: none !important;
}
.header-toggle-icon {
  display: flex !important;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (max-width: 488px) {
  .search-section-table {
    margin-top: 12px;
  }
}
/* Sidebar Header Start */

/* Sidebar Header End */

/* Table Style Start */
.table > thead {
  vertical-align: bottom;
  background-color: #e6eaef !important;
  color: #2d63a7 !important;
  /* border-bottom: 1px solid currentColor; */
}
/* Table Style End */

/* Filterfomr date picker */
.react-datepicker__input-container input[type='text'] {
  width: 100% !important;
  border: 1px solid #ced4da;
  height: 39px !important;
  border-radius: 5px !important;
}

/* Bootstrap table Start */
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.dropdown,
.dropup {
  position: relative;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: '';
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid\9;
}
/* Bootstrap table End */
/* Sidebar header */
.pro-sidebar-header {
  height: 62px;
}

/* ---------- Change Password Start ---------- */
.pass-wrapper {
  position: relative;
  width: 100%;
}

svg.eye-react-icons {
  position: absolute;
  right: 0.75em;
  top: 2.67em;
  z-index: 9;
}

/* ---------- Change Password End ---------- */

.default-user {
  vertical-align: top;
  margin-left: 8px;
  height: 30px;
  width: 30px;
  border-radius: 16px;
  top: 9px;
  display: inline-block;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url('https://www.bing.com/rp/5rqGloMo94v3vwNVR5OsxDNd8d0.svg');
}

