.five-t-sec {
  background: url(../../assets/images/icon/shape3.svg) no-repeat #fff;
  background-size: cover;
  min-height: 85px;
  display: flex;
  position: relative;
  z-index: 1;
  margin-top: -1px;
  clear: both;
}

.five-t-logo {
  float: left;
}

.five-t-logo img {
  height: 40px;
}

.five-t-high {
  display: none;
}

.five-t-listing {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  -webkit-transform: translateY(-30px);
  -moz-transform: translateY(-30px);
  -ms-transform: translateY(-30px);
  -o-transform: translateY(-30px);
  transform: translateY(-30px);
}

.five-t-listing li {
  display: inline-block;
  text-align: center;
  margin: 0 2px;
  min-width: 86px;
}

.five-t-listing li a {
  margin-top: -10px;
  display: block;
  color: #343434;
  font-size: 14px;
}

.five-t-listing li a:hover {
  text-decoration: none;
}

.five-t-listing li > span {
  display: block;
  background-color: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 10px;
}

.five-t-listing li > span img {
  transition: 0.7s;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -ms-transition: 0.7s;
  -o-transition: 0.7s;
  margin-top: 10px;
}

.five-t-listing li:hover > span img {
  transition: 0.7s;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -ms-transition: 0.7s;
  -o-transition: 0.7s;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.sec-first {
  width: 38%;
}

.sec-second {
  width: 64%;
  padding-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sec-second ul {
  float: right;
  margin: 5px 0 0 99px;
  padding: 0;
}

.sec-second ul li {
  display: inline-block;
  text-align: center;
  margin: 0 15px;
}

.sec-second ul li a {
  color: #fff;
  font-size: 9px;
  background-color: #033454;
}

.or .sec-second ul li {
  margin: 0 10px;
}

.or .sec-second ul li a {
  font-size: 11px;
}

.sec-second ul li a:hover {
  text-decoration: none;
}

@media all and (min--moz-device-pixel-ratio: 0) and (max-width: 1400px) {
  .five-t-sec {
    background-size: cover;
  }
  svg#odisha-map {
    height: 400px;
  }
  .navigation .nav-widget {
    margin-left: 50px;
  }
  .navigation .we-mega-menu-ul > .we-mega-menu-li {
    text-align: center;
  }
  .navigation .we-mega-menu-ul > .we-mega-menu-li > a,
  .navigation .we-mega-menu-ul > .we-mega-menu-li > span {
    padding: 12px 6px;
  }
  .navigation .dropdown-menu {
    min-width: 3.5em;
  }
  .navigation .we-mega-menu-ul > .we-mega-menu-li > a > i,
  .navigation .we-mega-menu-ul > .we-mega-menu-li > span > i {
    filter: contrast(1);
    -webkit-filter: contrast(1);
  }
  .navigation .we-mega-menu-submenu li.we-mega-menu-li {
    text-align: left;
  }
  .navigation.sticky.fixed .minister > a:nth-child(2) img {
    padding-top: 28px;
  }
  .navigation.fixed .minister a:nth-child(1) {
    padding-top: 35px;
  }
  .five-t-logo img {
    width: 90%;
  }
  .five-t-listing li {
    min-width: 80px;
  }
  .five-t-listing li > span + a {
    margin-left: 10px;
    margin-right: 10px;
    display: block;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .modalconent {
    width: 700px;
  }
  .sec-second ul {
    margin-top: 20px;
  }
  svg#odisha-map {
    height: 400px;
  }
  .navigation .nav-widget {
    margin-left: 20px;
  }
  .navigation .we-mega-menu-ul > .we-mega-menu-li {
    text-align: center;
  }
  .navigation .we-mega-menu-ul > .we-mega-menu-li > a,
  .navigation .we-mega-menu-ul > .we-mega-menu-li > span {
    padding: 12px 6px;
  }
  .navigation .dropdown-menu {
    min-width: 3.5em;
  }
  .navigation .we-mega-menu-ul > .we-mega-menu-li > a > i,
  .navigation .we-mega-menu-ul > .we-mega-menu-li > span > i {
    filter: contrast(0);
    -webkit-filter: contrast(0);
  }
  .navigation .we-mega-menu-submenu li.we-mega-menu-li {
    text-align: left;
  }
  .bird-flying {
    display: none;
  }
  .navigation.sticky.fixed .minister > a:nth-child(2) img {
    padding-top: 28px;
  }
  .navigation.fixed .minister a:nth-child(1) {
    padding-top: 35px;
  }
}

.views-exposed-form
  #edit-field-domain-access-target-id
  option[value='localhost'] {
  display: none;
}

@media (min-width: 1152px) and (max-width: 1300px) {
  .five-t-sec {
    background-size: cover;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .banner-widget .col-lg-8.col-md-7.col-sm-7 {
    max-width: 100%;
  }
  .ad-bg {
    margin: auto;
  }
}

@media (min-width: 750px) and (max-width: 1300px) {
  .or .sec-second ul li {
    margin: 0;
  }
  .service-content {
    padding-top: 0;
  }
  .flex1-child + .flex1-child {
    margin: 0;
  }
  .main-news .flex1-child {
    margin-bottom: 15px;
  }
  .content-item ul li > a {
    font-size: 80%;
  }
  .swf_language ul.links {
    right: 7%;
  }
  .logo-widget img {
    width: 80%;
  }
  .navigation .nav-widget {
    margin-left: -15px;
  }
  .navigation .we-mega-menu-ul > .we-mega-menu-li.active-trail > a,
  .navigation .we-mega-menu-row ul li > a,
  .navigation .we-mega-menu-row ul li > span.we-megamenu-nolink {
    font-size: 85%;
  }
}

.fade {
  transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  color: inherit;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-group > .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child .card-header,
  .card-group > .card:first-child .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group > .card:first-child .card-footer,
  .card-group > .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child .card-header,
  .card-group > .card:last-child .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group > .card:last-child .card-footer,
  .card-group > .card:last-child .card-img-bottom {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-header,
  .card-group > .card:only-child .card-img-top {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-footer,
  .card-group > .card:only-child .card-img-bottom {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-footer,
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-header,
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-img-bottom,
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-img-top {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion .card {
  overflow: hidden;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion .card .card-header {
  margin-bottom: -1px;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: '/';
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

a.badge:focus,
a.badge:hover {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

a.badge-primary:focus,
a.badge-primary:hover {
  color: #fff;
  background-color: #0062cc;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:focus,
a.badge-secondary:hover {
  color: #fff;
  background-color: #545b62;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

a.badge-success:focus,
a.badge-success:hover {
  color: #fff;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:focus,
a.badge-info:hover {
  color: #fff;
  background-color: #117a8b;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:focus,
a.badge-warning:hover {
  color: #212529;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.badge-danger:focus,
a.badge-danger:hover {
  color: #fff;
  background-color: #bd2130;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:focus,
a.badge-light:hover {
  color: #212529;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:focus,
a.badge-dark:hover {
  color: #fff;
  background-color: #1d2124;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -ms-flex: 1;
  flex: 1;
}

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (0.5rem * 2));
  content: '';
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-auto[x-placement^='top'],
.bs-tooltip-top {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[x-placement^='top'] .arrow,
.bs-tooltip-top .arrow {
  bottom: 0;
}

.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-auto[x-placement^='right'],
.bs-tooltip-right {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[x-placement^='right'] .arrow,
.bs-tooltip-right .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.bs-tooltip-right .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-auto[x-placement^='bottom'],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[x-placement^='bottom'] .arrow,
.bs-tooltip-bottom .arrow {
  top: 0;
}

.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-auto[x-placement^='left'],
.bs-tooltip-left {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[x-placement^='left'] .arrow,
.bs-tooltip-left .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.bs-tooltip-left .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::after,
.popover .arrow::before {
  position: absolute;
  display: block;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.bs-popover-auto[x-placement^='top'],
.bs-popover-top {
  margin-bottom: 0.5rem;
}

.bs-popover-auto[x-placement^='top'] .arrow,
.bs-popover-top .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-auto[x-placement^='top'] .arrow::after,
.bs-popover-auto[x-placement^='top'] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-top .arrow::before {
  border-width: 0.5rem 0.5rem 0;
}

.bs-popover-auto[x-placement^='top'] .arrow::before,
.bs-popover-top .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^='top'] .arrow::after,
.bs-popover-top .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}

.bs-popover-auto[x-placement^='right'],
.bs-popover-right {
  margin-left: 0.5rem;
}

.bs-popover-auto[x-placement^='right'] .arrow,
.bs-popover-right .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-auto[x-placement^='right'] .arrow::after,
.bs-popover-auto[x-placement^='right'] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-right .arrow::before {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-popover-auto[x-placement^='right'] .arrow::before,
.bs-popover-right .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^='right'] .arrow::after,
.bs-popover-right .arrow::after {
  left: 1px;
  border-right-color: #fff;
}

.bs-popover-auto[x-placement^='bottom'],
.bs-popover-bottom {
  margin-top: 0.5rem;
}

.bs-popover-auto[x-placement^='bottom'] .arrow,
.bs-popover-bottom .arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.bs-popover-auto[x-placement^='bottom'] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-bottom .arrow::before {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-popover-auto[x-placement^='bottom'] .arrow::before,
.bs-popover-bottom .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^='bottom'] .arrow::after,
.bs-popover-bottom .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}

.bs-popover-auto[x-placement^='bottom'] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: '';
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-auto[x-placement^='left'],
.bs-popover-left {
  margin-right: 0.5rem;
}

.bs-popover-auto[x-placement^='left'] .arrow,
.bs-popover-left .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-auto[x-placement^='left'] .arrow::after,
.bs-popover-auto[x-placement^='left'] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-left .arrow::before {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-popover-auto[x-placement^='left'] .arrow::before,
.bs-popover-left .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^='left'] .arrow::after,
.bs-popover-left .arrow::after {
  right: 1px;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: '';
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}

.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-control-next,
  .carousel-control-prev {
    transition: none;
  }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow 0.75s linear infinite;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.align-text-top {
  vertical-align: text-top;
}

.bg-primary {
  background-color: #007bff;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #0062cc;
}

.bg-secondary {
  background-color: #6c757d;
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #545b62;
}

.bg-success {
  background-color: #28a745;
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #1e7e34;
}

.bg-info {
  background-color: #17a2b8;
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #117a8b;
}

.bg-warning {
  background-color: #ffc107;
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #d39e00;
}

.bg-danger {
  background-color: #dc3545;
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #bd2130;
}

.bg-light {
  background-color: #f8f9fa;
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #dae0e5;
}

.bg-dark {
  background-color: #343a40;
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #1d2124;
}

.bg-white {
  background-color: #fff;
}

.bg-transparent {
  background-color: transparent;
}

.border {
  border: 1px solid #dee2e6;
}

.border-top {
  border-top: 1px solid #dee2e6;
}

.border-right {
  border-right: 1px solid #dee2e6;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6;
}

.border-left {
  border-left: 1px solid #dee2e6;
}

.border-0 {
  border: 0;
}

.border-top-0 {
  border-top: 0;
}

.border-right-0 {
  border-right: 0;
}

.border-bottom-0 {
  border-bottom: 0;
}

.border-left-0 {
  border-left: 0;
}

.border-primary {
  border-color: #007bff;
}

.border-secondary {
  border-color: #6c757d;
}

.border-success {
  border-color: #28a745;
}

.border-info {
  border-color: #17a2b8;
}

.border-warning {
  border-color: #ffc107;
}

.border-danger {
  border-color: #dc3545;
}

.border-light {
  border-color: #f8f9fa;
}

.border-dark {
  border-color: #343a40;
}

.border-white {
  border-color: #fff;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-top {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.rounded-right {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rounded-left {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-pill {
  border-radius: 50rem;
}

.rounded-0 {
  border-radius: 0;
}

.clearfix::after {
  display: block;
  clear: both;
  content: '';
}

.d-none {
  display: none;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-table {
  display: table;
}

.d-table-row {
  display: table-row;
}

.d-table-cell {
  display: table-cell;
}

.d-flex {
  display: -ms-flexbox;
  display: flex;
}

.d-inline-flex {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none;
  }
  .d-sm-inline {
    display: inline;
  }
  .d-sm-inline-block {
    display: inline-block;
  }
  .d-sm-block {
    display: block;
  }
  .d-sm-table {
    display: table;
  }
  .d-sm-table-row {
    display: table-row;
  }
  .d-sm-table-cell {
    display: table-cell;
  }
  .d-sm-flex {
    display: -ms-flexbox;
    display: flex;
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none;
  }
  .d-md-inline {
    display: inline;
  }
  .d-md-inline-block {
    display: inline-block;
  }
  .d-md-block {
    display: block;
  }
  .d-md-table {
    display: table;
  }
  .d-md-table-row {
    display: table-row;
  }
  .d-md-table-cell {
    display: table-cell;
  }
  .d-md-flex {
    display: -ms-flexbox;
    display: flex;
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none;
  }
  .d-lg-inline {
    display: inline;
  }
  .d-lg-inline-block {
    display: inline-block;
  }
  .d-lg-block {
    display: block;
  }
  .d-lg-table {
    display: table;
  }
  .d-lg-table-row {
    display: table-row;
  }
  .d-lg-table-cell {
    display: table-cell;
  }
  .d-lg-flex {
    display: -ms-flexbox;
    display: flex;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none;
  }
  .d-xl-inline {
    display: inline;
  }
  .d-xl-inline-block {
    display: inline-block;
  }
  .d-xl-block {
    display: block;
  }
  .d-xl-table {
    display: table;
  }
  .d-xl-table-row {
    display: table-row;
  }
  .d-xl-table-cell {
    display: table-cell;
  }
  .d-xl-flex {
    display: -ms-flexbox;
    display: flex;
  }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}

@media print {
  .d-print-none {
    display: none;
  }
  .d-print-inline {
    display: inline;
  }
  .d-print-inline-block {
    display: inline-block;
  }
  .d-print-block {
    display: block;
  }
  .d-print-table {
    display: table;
  }
  .d-print-table-row {
    display: table-row;
  }
  .d-print-table-cell {
    display: table-cell;
  }
  .d-print-flex {
    display: -ms-flexbox;
    display: flex;
  }
  .d-print-inline-flex {
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: '';
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-3by4::before {
  padding-top: 133.333333%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -ms-flex-direction: row;
  flex-direction: row;
}

.flex-column {
  -ms-flex-direction: column;
  flex-direction: column;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.flex-fill {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.flex-grow-0 {
  -ms-flex-positive: 0;
  flex-grow: 0;
}

.flex-grow-1 {
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.flex-shrink-0 {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.flex-shrink-1 {
  -ms-flex-negative: 1;
  flex-shrink: 1;
}

.justify-content-start {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.justify-content-end {
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.justify-content-center {
  -ms-flex-pack: center;
  justify-content: center;
}

.justify-content-between {
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.justify-content-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.align-items-start {
  -ms-flex-align: start;
  align-items: flex-start;
}

.align-items-end {
  -ms-flex-align: end;
  align-items: flex-end;
}

.align-items-center {
  -ms-flex-align: center;
  align-items: center;
}

.align-items-baseline {
  -ms-flex-align: baseline;
  align-items: baseline;
}

.align-items-stretch {
  -ms-flex-align: stretch;
  align-items: stretch;
}

.align-content-start {
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.align-content-end {
  -ms-flex-line-pack: end;
  align-content: flex-end;
}

.align-content-center {
  -ms-flex-line-pack: center;
  align-content: center;
}

.align-content-between {
  -ms-flex-line-pack: justify;
  align-content: space-between;
}

.align-content-around {
  -ms-flex-line-pack: distribute;
  align-content: space-around;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

.align-self-auto {
  -ms-flex-item-align: auto;
  align-self: auto;
}

.align-self-start {
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.align-self-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.align-self-center {
  -ms-flex-item-align: center;
  align-self: center;
}

.align-self-baseline {
  -ms-flex-item-align: baseline;
  align-self: baseline;
}

.align-self-stretch {
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .flex-sm-column {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }
  .flex-sm-fill {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1;
    flex-shrink: 1;
  }
  .justify-content-sm-start {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .justify-content-sm-end {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .justify-content-sm-center {
    -ms-flex-pack: center;
    justify-content: center;
  }
  .justify-content-sm-between {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .align-items-sm-start {
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .align-items-sm-end {
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .align-items-sm-center {
    -ms-flex-align: center;
    align-items: center;
  }
  .align-items-sm-baseline {
    -ms-flex-align: baseline;
    align-items: baseline;
  }
  .align-items-sm-stretch {
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify;
    align-content: space-between;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto;
    align-self: auto;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center;
    align-self: center;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline;
    align-self: baseline;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .flex-md-column {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }
  .flex-md-fill {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .flex-md-grow-0 {
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
  .flex-md-grow-1 {
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1;
    flex-shrink: 1;
  }
  .justify-content-md-start {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .justify-content-md-end {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .justify-content-md-center {
    -ms-flex-pack: center;
    justify-content: center;
  }
  .justify-content-md-between {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .align-items-md-start {
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .align-items-md-end {
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .align-items-md-center {
    -ms-flex-align: center;
    align-items: center;
  }
  .align-items-md-baseline {
    -ms-flex-align: baseline;
    align-items: baseline;
  }
  .align-items-md-stretch {
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify;
    align-content: space-between;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto;
    align-self: auto;
  }
  .align-self-md-start {
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .align-self-md-end {
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .align-self-md-center {
    -ms-flex-item-align: center;
    align-self: center;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline;
    align-self: baseline;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .flex-lg-column {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }
  .flex-lg-fill {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1;
    flex-shrink: 1;
  }
  .justify-content-lg-start {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .justify-content-lg-end {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .justify-content-lg-center {
    -ms-flex-pack: center;
    justify-content: center;
  }
  .justify-content-lg-between {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .align-items-lg-start {
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .align-items-lg-end {
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .align-items-lg-center {
    -ms-flex-align: center;
    align-items: center;
  }
  .align-items-lg-baseline {
    -ms-flex-align: baseline;
    align-items: baseline;
  }
  .align-items-lg-stretch {
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify;
    align-content: space-between;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto;
    align-self: auto;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center;
    align-self: center;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline;
    align-self: baseline;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .flex-xl-column {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }
  .flex-xl-fill {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1;
    flex-shrink: 1;
  }
  .justify-content-xl-start {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .justify-content-xl-end {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .justify-content-xl-center {
    -ms-flex-pack: center;
    justify-content: center;
  }
  .justify-content-xl-between {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .align-items-xl-start {
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .align-items-xl-end {
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .align-items-xl-center {
    -ms-flex-align: center;
    align-items: center;
  }
  .align-items-xl-baseline {
    -ms-flex-align: baseline;
    align-items: baseline;
  }
  .align-items-xl-stretch {
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify;
    align-content: space-between;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto;
    align-self: auto;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center;
    align-self: center;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline;
    align-self: baseline;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-none {
  float: none;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left;
  }
  .float-sm-right {
    float: right;
  }
  .float-sm-none {
    float: none;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left;
  }
  .float-md-right {
    float: right;
  }
  .float-md-none {
    float: none;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left;
  }
  .float-lg-right {
    float: right;
  }
  .float-lg-none {
    float: none;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left;
  }
  .float-xl-right {
    float: right;
  }
  .float-xl-none {
    float: none;
  }
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.position-static {
  position: static;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: -webkit-sticky;
  position: sticky;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}

.shadow-none {
  box-shadow: none;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.h-25 {
  height: 25%;
}

.h-50 {
  height: 50%;
}

.h-75 {
  height: 75%;
}

.h-100 {
  height: 100%;
}

.h-auto {
  height: auto;
}

.mw-100 {
  max-width: 100%;
}

.mh-100 {
  max-height: 100%;
}

.min-vw-100 {
  min-width: 100vw;
}

.min-vh-100 {
  min-height: 100vh;
}

.vw-100 {
  width: 100vw;
}

.vh-100 {
  height: 100vh;
}

.m-0 {
  margin: 0;
}

.mt-0,
.my-0 {
  margin-top: 0;
}

.mr-0,
.mx-0 {
  margin-right: 0;
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
}

.text-justify {
  text-align: justify;
}

.text-wrap {
  white-space: normal;
}

.text-nowrap {
  white-space: nowrap;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left;
  }
  .text-sm-right {
    text-align: right;
  }
  .text-sm-center {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left;
  }
  .text-md-right {
    text-align: right;
  }
  .text-md-center {
    text-align: center;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left;
  }
  .text-lg-right {
    text-align: right;
  }
  .text-lg-center {
    text-align: center;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left;
  }
  .text-xl-right {
    text-align: right;
  }
  .text-xl-center {
    text-align: center;
  }
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-lighter {
  font-weight: lighter;
}

.font-weight-normal {
  font-weight: 400;
}

.font-weight-bold {
  font-weight: 700;
}

.font-weight-bolder {
  font-weight: bolder;
}

.font-italic {
  font-style: italic;
}

.text-white {
  color: #fff;
}

.text-primary {
  color: #007bff;
}

a.text-primary:focus,
a.text-primary:hover {
  color: #fff;
}

.text-secondary {
  color: #6c757d;
}

a.text-secondary:focus,
a.text-secondary:hover {
  color: #494f54;
}

.text-success {
  color: #28a745;
}

a.text-success:focus,
a.text-success:hover {
  color: #19692c;
}

.text-info {
  color: #17a2b8;
}

a.text-info:focus,
a.text-info:hover {
  color: #0f6674;
}

.text-warning {
  color: #ffc107;
}

a.text-warning:focus,
a.text-warning:hover {
  color: #ba8b00;
}

.text-danger {
  color: #dc3545;
}

a.text-danger:focus,
a.text-danger:hover {
  color: #a71d2a;
}

.text-light {
  color: #f8f9fa;
}

a.text-light:focus,
a.text-light:hover {
  color: #cbd3da;
}

.text-dark {
  color: #343a40;
}

a.text-dark:focus,
a.text-dark:hover {
  color: #121416;
}

.text-body {
  color: #212529;
}

.text-muted {
  color: #6c757d;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5);
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5);
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none;
}

.text-reset {
  color: inherit;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

@media print {
  *,
  ::after,
  ::before {
    text-shadow: none;
    box-shadow: none;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: ' (' attr(title) ')';
  }
  pre {
    white-space: pre-wrap;
  }
  blockquote,
  pre {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  img,
  tr {
    page-break-inside: avoid;
  }
  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  body {
    min-width: 992px;
  }
  .container {
    min-width: 992px;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse;
  }
  .table td,
  .table th {
    background-color: #fff;
  }
  .table-bordered td,
  .table-bordered th {
    border: 1px solid #dee2e6;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark tbody + tbody,
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

.cd-header {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #21272c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (min-width: 768px) {
  .cd-header {
    height: 70px;
    background-color: transparent;
  }
}

#cd-logo {
  float: left;
  margin: 13px 0 0 5%;
}

#cd-logo img {
  display: block;
}

@media only screen and (min-width: 768px) {
  #cd-logo {
    margin: 23px 0 0 5%;
  }
}

.cd-primary-nav {
  float: right;
  margin-right: 5%;
  width: 44px;
  height: 100%;
}

.cd-primary-nav ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}

.cd-primary-nav ul.is-visible {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(50px);
  -moz-transform: translateY(50px);
  -ms-transform: translateY(50px);
  -o-transform: translateY(50px);
  transform: translateY(50px);
}

.cd-primary-nav a {
  display: block;
  height: 50px;
  line-height: 50px;
  padding-left: 5%;
  background: #21272c;
  border-top: 1px solid #333c44;
  color: #ffffff;
}

@media only screen and (min-width: 768px) {
  .cd-primary-nav {
    width: auto;
    height: auto;
    background: none;
  }
  .cd-primary-nav ul {
    position: static;
    width: auto;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    line-height: 70px;
  }
  .cd-primary-nav ul.is-visible {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  .cd-primary-nav li {
    display: inline-block;
    margin-left: 1em;
  }
  .cd-primary-nav a {
    display: inline-block;
    height: auto;
    font-weight: 600;
    line-height: normal;
    background: transparent;
    padding: 0.6em 1em;
    border-top: none;
  }
}

.cd-hero {
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cd-hero-slider {
  position: relative;
  height: 360px;
  overflow: hidden;
}

.cd-hero-slider li {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
}

.cd-hero-slider li.selected {
  position: relative;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.cd-hero-slider li.move-left {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
}

.cd-hero-slider li.is-moving,
.cd-hero-slider li.selected {
  -webkit-transition: -webkit-transform 0.5s;
  -moz-transition: -moz-transform 0.5s;
  transition: transform 0.5s;
}

@media only screen and (min-width: 768px) {
  .cd-hero-slider {
    height: 500px;
  }
}

@media only screen and (min-width: 1170px) {
  .cd-hero-slider {
    height: 680px;
  }
}

.cd-hero-slider li {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.cd-hero-slider .cd-full-width,
.cd-hero-slider .cd-half-width {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  padding-top: 80px;
  text-align: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}

.cd-hero-slider .cd-img-container {
  display: none;
}

.cd-hero-slider .cd-img-container img {
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.cd-hero-slider .cd-bg-video-wrapper {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.cd-hero-slider .cd-bg-video-wrapper video {
  display: block;
  min-height: 100%;
  min-width: 100%;
  max-width: none;
  height: auto;
  width: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.cd-hero-slider h2,
.cd-hero-slider p {
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  line-height: 1.2;
  margin: 0 auto 14px;
  color: #ffffff;
  width: 90%;
  max-width: 400px;
}

.cd-hero-slider h2 {
  font-size: 2.4rem;
}

.cd-hero-slider p {
  font-size: 1.4rem;
  line-height: 1.4;
}

.cd-hero-slider .cd-btn {
  display: inline-block;
  padding: 1.2em 1.4em;
  margin-top: 0.8em;
  background-color: rgba(212, 68, 87, 0.9);
  font-size: 1.3rem;
  font-weight: 700;
  letter-spacing: 1px;
  color: #ffffff;
  text-transform: uppercase;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  -webkit-transition: background-color 0.2s;
  -moz-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.cd-hero-slider .cd-btn.secondary {
  background-color: rgba(22, 26, 30, 0.8);
}

.cd-hero-slider .cd-btn:nth-of-type(2) {
  margin-left: 1em;
}

.no-touch .cd-hero-slider .cd-btn:hover {
  background-color: #d44457;
}

.no-touch .cd-hero-slider .cd-btn.secondary:hover {
  background-color: #161a1e;
}

@media only screen and (min-width: 768px) {
  .cd-hero-slider .cd-full-width,
  .cd-hero-slider .cd-half-width {
    padding-top: 150px;
  }
  .cd-hero-slider .cd-bg-video-wrapper {
    display: block;
  }
  .cd-hero-slider .cd-half-width {
    width: 45%;
  }
  .cd-hero-slider .cd-half-width:first-of-type {
    left: 5%;
  }
  .cd-hero-slider .cd-half-width:nth-of-type(2) {
    right: 5%;
    left: auto;
  }
  .cd-hero-slider .cd-img-container {
    display: block;
  }
  .cd-hero-slider h2,
  .cd-hero-slider p {
    max-width: 520px;
  }
  .cd-hero-slider h2 {
    font-size: 2.4em;
    font-weight: 300;
  }
  .cd-hero-slider .cd-btn {
    font-size: 1.4rem;
  }
}

@media only screen and (min-width: 1170px) {
  .cd-hero-slider .cd-full-width,
  .cd-hero-slider .cd-half-width {
    padding-top: 250px;
  }
  .cd-hero-slider h2,
  .cd-hero-slider p {
    margin-bottom: 20px;
  }
  .cd-hero-slider h2 {
    font-size: 3.2em;
  }
  .cd-hero-slider p {
    font-size: 1.6rem;
  }
}

@media only screen and (min-width: 768px) {
  .cd-hero-slider .cd-half-width {
    opacity: 0;
    -webkit-transform: translateX(40px);
    -moz-transform: translateX(40px);
    -ms-transform: translateX(40px);
    -o-transform: translateX(40px);
    transform: translateX(40px);
  }
  .cd-hero-slider .move-left .cd-half-width {
    -webkit-transform: translateX(-40px);
    -moz-transform: translateX(-40px);
    -ms-transform: translateX(-40px);
    -o-transform: translateX(-40px);
    transform: translateX(-40px);
  }
  .cd-hero-slider .selected .cd-half-width {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  .cd-hero-slider .is-moving .cd-half-width {
    -webkit-transition: opacity 0s 0.5s, -webkit-transform 0s 0.5s;
    -moz-transition: opacity 0s 0.5s, -moz-transform 0s 0.5s;
    transition: opacity 0s 0.5s, transform 0s 0.5s;
  }
  .cd-hero-slider li.selected.from-left .cd-half-width:nth-of-type(2),
  .cd-hero-slider li.selected.from-right .cd-half-width:first-of-type {
    -webkit-transition: opacity 0.4s 0.2s, -webkit-transform 0.5s 0.2s;
    -moz-transition: opacity 0.4s 0.2s, -moz-transform 0.5s 0.2s;
    transition: opacity 0.4s 0.2s, transform 0.5s 0.2s;
  }
  .cd-hero-slider li.selected.from-left .cd-half-width:first-of-type,
  .cd-hero-slider li.selected.from-right .cd-half-width:nth-of-type(2) {
    -webkit-transition: opacity 0.4s 0.4s, -webkit-transform 0.5s 0.4s;
    -moz-transition: opacity 0.4s 0.4s, -moz-transform 0.5s 0.4s;
    transition: opacity 0.4s 0.4s, transform 0.5s 0.4s;
  }
  .cd-hero-slider .cd-full-width h2,
  .cd-hero-slider .cd-full-width p,
  .cd-hero-slider .cd-full-width .cd-btn {
    opacity: 0;
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
  }
  .cd-hero-slider .move-left .cd-full-width h2,
  .cd-hero-slider .move-left .cd-full-width p,
  .cd-hero-slider .move-left .cd-full-width .cd-btn {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  .cd-hero-slider .selected .cd-full-width h2,
  .cd-hero-slider .selected .cd-full-width p,
  .cd-hero-slider .selected .cd-full-width .cd-btn {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  .cd-hero-slider li.is-moving .cd-full-width h2,
  .cd-hero-slider li.is-moving .cd-full-width p,
  .cd-hero-slider li.is-moving .cd-full-width .cd-btn {
    -webkit-transition: opacity 0s 0.5s, -webkit-transform 0s 0.5s;
    -moz-transition: opacity 0s 0.5s, -moz-transform 0s 0.5s;
    transition: opacity 0s 0.5s, transform 0s 0.5s;
  }
  .cd-hero-slider li.selected h2 {
    -webkit-transition: opacity 0.4s 0.2s, -webkit-transform 0.5s 0.2s;
    -moz-transition: opacity 0.4s 0.2s, -moz-transform 0.5s 0.2s;
    transition: opacity 0.4s 0.2s, transform 0.5s 0.2s;
  }
  .cd-hero-slider li.selected p {
    -webkit-transition: opacity 0.4s 0.3s, -webkit-transform 0.5s 0.3s;
    -moz-transition: opacity 0.4s 0.3s, -moz-transform 0.5s 0.3s;
    transition: opacity 0.4s 0.3s, transform 0.5s 0.3s;
  }
  .cd-hero-slider li.selected .cd-btn {
    -webkit-transition: opacity 0.4s 0.4s, -webkit-transform 0.5s 0.4s,
      background-color 0.2s 0s;
    -moz-transition: opacity 0.4s 0.4s, -moz-transform 0.5s 0.4s,
      background-color 0.2s 0s;
    transition: opacity 0.4s 0.4s, transform 0.5s 0.4s, background-color 0.2s 0s;
  }
}

.cd-slider-nav {
  display: none;
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 2;
  text-align: center;
  height: 55px;
  background-color: rgba(0, 1, 1, 0.5);
}

.cd-slider-nav nav,
.cd-slider-nav ul,
.cd-slider-nav li,
.cd-slider-nav a {
  height: 100%;
}

.cd-slider-nav nav {
  display: inline-block;
  position: relative;
}

.cd-slider-nav .cd-marker {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 100%;
  color: #d44457;
  background-color: #ffffff;
  box-shadow: inset 0 2px 0 currentColor;
  -webkit-transition: -webkit-transform 0.2s, box-shadow 0.2s;
  -moz-transition: -moz-transform 0.2s, box-shadow 0.2s;
  transition: transform 0.2s, box-shadow 0.2s;
}

.cd-slider-nav .cd-marker.item-2 {
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
}

.cd-slider-nav .cd-marker.item-3 {
  -webkit-transform: translateX(200%);
  -moz-transform: translateX(200%);
  -ms-transform: translateX(200%);
  -o-transform: translateX(200%);
  transform: translateX(200%);
}

.cd-slider-nav .cd-marker.item-4 {
  -webkit-transform: translateX(300%);
  -moz-transform: translateX(300%);
  -ms-transform: translateX(300%);
  -o-transform: translateX(300%);
  transform: translateX(300%);
}

.cd-slider-nav .cd-marker.item-5 {
  -webkit-transform: translateX(400%);
  -moz-transform: translateX(400%);
  -ms-transform: translateX(400%);
  -o-transform: translateX(400%);
  transform: translateX(400%);
}

.cd-slider-nav ul::after {
  clear: both;
  content: '';
  display: table;
}

.cd-slider-nav li {
  display: inline-block;
  width: 60px;
  float: left;
}

.cd-slider-nav li.selected a {
  color: #2c343b;
}

.no-touch .cd-slider-nav li.selected a:hover {
  background-color: transparent;
}

.cd-slider-nav a {
  display: block;
  position: relative;
  padding-top: 35px;
  font-size: 1rem;
  font-weight: 700;
  color: #a8b4be;
  -webkit-transition: background-color 0.2s;
  -moz-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.cd-slider-nav a::before {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  top: 8px;
  left: 50%;
  right: auto;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.no-touch .cd-slider-nav a:hover {
  background-color: rgba(0, 1, 1, 0.5);
}

.cd-slider-nav li:first-of-type a::before {
  background-position: 0 0;
}

.cd-slider-nav li.selected:first-of-type a::before {
  background-position: 0 -24px;
}

.cd-slider-nav li:nth-of-type(2) a::before {
  background-position: -24px 0;
}

.cd-slider-nav li.selected:nth-of-type(2) a::before {
  background-position: -24px -24px;
}

.cd-slider-nav li:nth-of-type(3) a::before {
  background-position: -48px 0;
}

.cd-slider-nav li.selected:nth-of-type(3) a::before {
  background-position: -48px -24px;
}

.cd-slider-nav li:nth-of-type(4) a::before {
  background-position: -72px 0;
}

.cd-slider-nav li.selected:nth-of-type(4) a::before {
  background-position: -72px -24px;
}

.cd-slider-nav li:nth-of-type(5) a::before {
  background-position: -96px 0;
}

.cd-slider-nav li.selected:nth-of-type(5) a::before {
  background-position: -96px -24px;
}

@media only screen and (min-width: 768px) {
  .cd-slider-nav {
    height: 80px;
  }
  .cd-slider-nav .cd-marker,
  .cd-slider-nav li {
    width: 95px;
  }
  .cd-slider-nav a {
    padding-top: 48px;
    font-size: 1.1rem;
    text-transform: uppercase;
  }
  .cd-slider-nav a::before {
    top: 18px;
  }
}

.cd-main-content {
  width: 90%;
  max-width: 768px;
  margin: 0 auto;
  padding: 2em 0;
}

.cd-main-content p {
  font-size: 1.4rem;
  line-height: 1.8;
  color: #999999;
  margin: 2em 0;
}

@media only screen and (min-width: 1170px) {
  .cd-main-content {
    padding: 3em 0;
  }
  .cd-main-content p {
    font-size: 1.6rem;
  }
}

.no-js .cd-hero-slider li {
  display: none;
}

.no-js .cd-hero-slider li.selected {
  display: block;
}

.no-js .cd-slider-nav {
  display: none;
}

@-webkit-keyframes bounce {
  0%,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}

@-webkit-keyframes flash {
  0%,
  50%,
  to {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

@keyframes flash {
  0%,
  50%,
  to {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

.flash {
  -webkit-animation-name: flash;
  animation-name: flash;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

@-webkit-keyframes rubberBand {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes rubberBand {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}

@-webkit-keyframes shake {
  0%,
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

@keyframes shake {
  0%,
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

.shake {
  -webkit-animation-name: shake;
  animation-name: shake;
}

@-webkit-keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }
  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.headShake {
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-name: headShake;
  animation-name: headShake;
}

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes swing {
  20% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
}

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
  }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes tada {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
  }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.tada {
  -webkit-animation-name: tada;
  animation-name: tada;
}

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate(-5deg);
    transform: translate3d(-25%, 0, 0) rotate(-5deg);
  }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate(3deg);
    transform: translate3d(20%, 0, 0) rotate(3deg);
  }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate(-3deg);
    transform: translate3d(-15%, 0, 0) rotate(-3deg);
  }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate(2deg);
    transform: translate3d(10%, 0, 0) rotate(2deg);
  }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate(-1deg);
    transform: translate3d(-5%, 0, 0) rotate(-1deg);
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes wobble {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate(-5deg);
    transform: translate3d(-25%, 0, 0) rotate(-5deg);
  }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate(3deg);
    transform: translate3d(20%, 0, 0) rotate(3deg);
  }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate(-3deg);
    transform: translate3d(-15%, 0, 0) rotate(-3deg);
  }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate(2deg);
    transform: translate3d(10%, 0, 0) rotate(2deg);
  }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate(-1deg);
    transform: translate3d(-5%, 0, 0) rotate(-1deg);
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble;
}

@-webkit-keyframes jello {
  0%,
  11.1%,
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

@keyframes jello {
  0%,
  11.1%,
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

.jello {
  -webkit-animation-name: jello;
  animation-name: jello;
  -webkit-transform-origin: center;
  transform-origin: center;
}

@-webkit-keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.heartBeat {
  -webkit-animation-name: heartBeat;
  animation-name: heartBeat;
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

@-webkit-keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.bounceIn {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}

@-webkit-keyframes bounceInDown {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes bounceInDown {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

@-webkit-keyframes bounceInLeft {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes bounceInLeft {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}

@-webkit-keyframes bounceInRight {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes bounceInRight {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight;
}

@-webkit-keyframes bounceInUp {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes bounceInUp {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp;
}

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

.bounceOut {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
}

@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}

@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}

.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown;
}

@-webkit-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}

.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft;
}

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}

.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight;
}

@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}

.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}

@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}

.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig;
}

@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}

.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig;
}

@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}

@-webkit-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}

.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig;
}

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@-webkit-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}

.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig;
}

@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) scaleX(1) translateZ(0)
      rotateY(-1turn);
    transform: perspective(400px) scaleX(1) translateZ(0) rotateY(-1turn);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) scaleX(1) translateZ(150px)
      rotateY(-190deg);
    transform: perspective(400px) scaleX(1) translateZ(150px) rotateY(-190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) scaleX(1) translateZ(150px)
      rotateY(-170deg);
    transform: perspective(400px) scaleX(1) translateZ(150px) rotateY(-170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95)
      translateZ(0) rotateY(0deg);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translateZ(0)
      rotateY(0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  to {
    -webkit-transform: perspective(400px) scaleX(1) translateZ(0) rotateY(0deg);
    transform: perspective(400px) scaleX(1) translateZ(0) rotateY(0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}

@keyframes flip {
  0% {
    -webkit-transform: perspective(400px) scaleX(1) translateZ(0)
      rotateY(-1turn);
    transform: perspective(400px) scaleX(1) translateZ(0) rotateY(-1turn);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) scaleX(1) translateZ(150px)
      rotateY(-190deg);
    transform: perspective(400px) scaleX(1) translateZ(150px) rotateY(-190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) scaleX(1) translateZ(150px)
      rotateY(-170deg);
    transform: perspective(400px) scaleX(1) translateZ(150px) rotateY(-170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95)
      translateZ(0) rotateY(0deg);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95) translateZ(0)
      rotateY(0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  to {
    -webkit-transform: perspective(400px) scaleX(1) translateZ(0) rotateY(0deg);
    transform: perspective(400px) scaleX(1) translateZ(0) rotateY(0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}

.animated.flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip;
}

@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-20deg);
    transform: perspective(400px) rotateX(-20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotateX(-5deg);
    transform: perspective(400px) rotateX(-5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-20deg);
    transform: perspective(400px) rotateX(-20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotateX(-5deg);
    transform: perspective(400px) rotateX(-5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

.flipInX {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
}

@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateY(-20deg);
    transform: perspective(400px) rotateY(-20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotateY(-5deg);
    transform: perspective(400px) rotateY(-5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

@keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateY(-20deg);
    transform: perspective(400px) rotateY(-20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotateY(-5deg);
    transform: perspective(400px) rotateY(-5deg);
  }
  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

.flipInY {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
}

@-webkit-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotateX(-20deg);
    transform: perspective(400px) rotateX(-20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}

@keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotateX(-20deg);
    transform: perspective(400px) rotateX(-20deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}

.flipOutX {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
}

@-webkit-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotateY(-15deg);
    transform: perspective(400px) rotateY(-15deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}

@keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
  30% {
    -webkit-transform: perspective(400px) rotateY(-15deg);
    transform: perspective(400px) rotateY(-15deg);
    opacity: 1;
  }
  to {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}

.flipOutY {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY;
}

@-webkit-keyframes lightSpeedIn {
  0% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes lightSpeedIn {
  0% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

@-webkit-keyframes lightSpeedOut {
  0% {
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}

@keyframes lightSpeedOut {
  0% {
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}

.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}

@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes rotateIn {
  0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(-200deg);
    transform: rotate(-200deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn;
}

@-webkit-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft;
}

@-webkit-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight;
}

@-webkit-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft;
}

@-webkit-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: 0;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight;
}

@-webkit-keyframes rotateOut {
  0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(200deg);
    transform: rotate(200deg);
    opacity: 0;
  }
}

@keyframes rotateOut {
  0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(200deg);
    transform: rotate(200deg);
    opacity: 0;
  }
}

.rotateOut {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut;
}

@-webkit-keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
  }
}

@keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
  }
}

.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft;
}

@-webkit-keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0;
  }
}

@keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0;
  }
}

.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight;
}

@-webkit-keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0;
  }
}

@keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0;
  }
}

.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft;
}

@-webkit-keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}

@keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }
  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    opacity: 0;
  }
}

.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight;
}

@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%,
  80% {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}

@keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%,
  80% {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}

.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-name: hinge;
  animation-name: hinge;
}

@-webkit-keyframes jackInTheBox {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }
  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes jackInTheBox {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }
  50% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  70% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.jackInTheBox {
  -webkit-animation-name: jackInTheBox;
  animation-name: jackInTheBox;
}

@-webkit-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate(-120deg);
    transform: translate3d(-100%, 0, 0) rotate(-120deg);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate(-120deg);
    transform: translate3d(-100%, 0, 0) rotate(-120deg);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn;
}

@-webkit-keyframes rollOut {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate(120deg);
    transform: translate3d(100%, 0, 0) rotate(120deg);
  }
}

@keyframes rollOut {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate(120deg);
    transform: translate3d(100%, 0, 0) rotate(120deg);
  }
}

.rollOut {
  -webkit-animation-name: rollOut;
  animation-name: rollOut;
}

@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

@-webkit-keyframes zoomInDown {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInDown {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown;
}

@-webkit-keyframes zoomInLeft {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInLeft {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft;
}

@-webkit-keyframes zoomInRight {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInRight {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInRight {
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight;
}

@-webkit-keyframes zoomInUp {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInUp {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInUp {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp;
}

@-webkit-keyframes zoomOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}

@keyframes zoomOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}

.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut;
}

@-webkit-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomOutDown {
  -webkit-animation-name: zoomOutDown;
  animation-name: zoomOutDown;
}

@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}

.zoomOutLeft {
  -webkit-animation-name: zoomOutLeft;
  animation-name: zoomOutLeft;
}

@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}

.zoomOutRight {
  -webkit-animation-name: zoomOutRight;
  animation-name: zoomOutRight;
}

@-webkit-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomOutUp {
  -webkit-animation-name: zoomOutUp;
  animation-name: zoomOutUp;
}

@-webkit-keyframes slideInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes slideInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes slideInRight {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

@-webkit-keyframes slideOutDown {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes slideOutDown {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
}

@-webkit-keyframes slideOutLeft {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes slideOutLeft {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}

@-webkit-keyframes slideOutRight {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes slideOutRight {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
}

@-webkit-keyframes slideOutUp {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes slideOutUp {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
}

@media (prefers-reduced-motion: reduce), (print) {
  .animated {
    -webkit-animation-duration: 1ms;
    animation-duration: 1ms;
    -webkit-transition-duration: 1ms;
    transition-duration: 1ms;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
  }
}

body {
  position: relative;
  font-size: 14px;
}

@media screen and (min-width: 1920px) {
  .ad-bg iframe {
    width: 100%;
    height: 50vh;
  }
  .advertisement-blog img {
    min-height: 69vh;
  }
  .content-right .container {
    max-width: 100%;
  }
  .five-t-sec .sec-first .content {
    float: left;
  }
  .five-t-logo {
    float: left;
    margin-left: 10px;
    margin-top: 9px;
  }
  .content-right {
    width: 85%;
  }
  .swf_language ul.links {
    right: 22%;
  }
  .no-shadow {
    background-size: 38%;
  }
  .banner-slider .slides li > img {
    height: 50vh;
  }
  .banner-widget {
    height: 50vh;
  }
  .banner-widget {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .logo-widget .odisha-logo img {
    max-width: 160px;
  }
  .ad-bg iframe + div.advertisement-blog {
    display: none;
  }
  .ad-bg iframe {
    width: 100%;
    height: 32vh;
    padding: 20px 0;
  }
  .advertisement-blog img {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 485px) and (max-width: 736px) {
  .ad-bg iframe + div.advertisement-blog {
    display: none;
  }
  .ad-bg iframe {
    width: 100%;
    height: 410px;
    padding: 20px 0;
  }
  .five-t-logo {
    width: 80%;
  }
}

@media (min-width: 1281px) {
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .path-_t-photo-gallery ._tgallerylist .item-list ul li img {
    height: 150px;
  }
  .box1 {
    max-width: 707px;
  }
  .path-state-holidays
    .js-drupal-fullcalendar
    .fc-month-view
    > .scroll-table1
    > table {
    table-layout: auto;
    width: 1024px;
  }
  .path-state-holidays .js-drupal-fullcalendar .fc-month-view > .guide-text {
    display: block;
  }
  .five-t-logo img {
    width: 85%;
  }
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .content-right {
    width: 76%;
  }
  .socialwalltab ul li {
    width: 32%;
  }
  .page-node-2237 .views-row,
  .page-node-2295 .quicktabs-tabpage .views-row,
  .page-node-2296 .quicktabs-tabpage .views-row,
  .page-node-2297 .quicktabs-tabpage .views-row {
    width: 48%;
    margin: 0;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .page-node-2295 .quicktabs-tabpage .socialAreat_tweet .te_feeds {
    min-height: 160px;
  }
  .page-node-2295 .quicktabs-tabpage .tw_bottomPanel .twoneside span {
    margin-right: 10px;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {
  .eminent-personalities > .views-row {
    width: 47%;
    margin: 0 10px 60px;
  }
  .eminent-personalities > .views-row {
    width: 47%;
    margin: 0 10px 60px;
  }
}

@media (min-width: 768px) and (max-width: 1180px) {
  .five-t-listing {
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  .five-t-sec {
    background: none;
    display: block;
  }
  .sec-first {
    width: 100%;
    background-color: #fff;
    height: 71px;
  }
  .sec-second {
    width: 100%;
    background-color: #033454;
    padding: 15px 0;
  }
  .five-t-listing li {
    min-width: 120px;
  }
  .sec-second ul li {
    margin: 0 10px;
  }
  .five-t-logo {
    padding-top: 12px;
    background-color: #3a95c2;
    min-height: 69px;
    min-width: 110px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .ad-bg iframe {
    width: 100%;
    height: 25vh;
  }
  .navigation .minister a:nth-child(1) {
    margin-top: 25px;
  }
  .navigation .minister {
    bottom: 0;
  }
  .navigation .minister img {
    width: 70px;
  }
  .navigation.sticky.fixed .minister > img {
    width: 70px;
  }
  .fixed .minister {
    width: auto;
  }
  .navigation.sticky.fixed .minister > a {
    padding-top: 22px;
  }
  .navigation .minister a {
    padding-top: 20px;
  }
  .odisha-logo {
    margin-bottom: 7px;
  }
  .banner-slider .slides li > img,
  .advertisement-blog img,
  .banner-widget {
    height: 25vh;
  }
  .banner-widget .col-md-3 {
    position: absolute;
    top: 0;
    right: 0;
  }
  .five-t-listing {
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  .five-t-sec {
    background: none;
    display: block;
  }
  .sec-first {
    width: 100%;
    background-color: #fff;
    height: 71px;
  }
  .sec-second {
    width: 100%;
    background-color: #033454;
    padding: 15px 0;
  }
  .five-t-listing li {
    min-width: 120px;
  }
  .sec-second ul li {
    margin: 0 10px;
  }
  .five-t-logo {
    padding-top: 12px;
    background-color: #3a95c2;
    min-height: 69px;
    min-width: 110px;
  }
  .dark-bg {
    display: block;
    float: left;
    width: 100%;
  }
  .main-news .flex1-child {
    width: 49%;
    float: left;
  }
  .main-news .flex1-child:nth-child(2),
  .main-news .flex1-child:nth-child(4) {
    float: right;
  }
  .flex1-child + .flex1-child {
    margin: 0;
  }
  .main-news .flex1-child {
    margin-bottom: 15px;
  }
  .hidden-treasure {
    clear: both;
  }
  .hidden-treasure .teasure-gallery .treasure-gallery-card > img {
    height: 120px;
  }
  .hidden-treasure
    .teasure-gallery
    .treasure-gallery-card
    .trasure-gallery-content {
    position: static;
    padding-top: 135px;
  }
  .hidden-treasure
    .teasure-gallery
    .treasure-gallery-card
    .trasure-gallery-content
    h4 {
    font-size: 19px;
  }
  .hidden-treasure .teasure-gallery .treasure-gallery-card {
    min-height: 380px;
    margin-bottom: 10px;
  }
  .related-link ul li {
    width: 100%;
  }
  .odisha-profile-area ul {
    width: 50%;
  }
  .col-1-5 {
    width: 50%;
  }
  .teasure-gallery ul.slides {
    width: 100%;
  }
  .teasure-gallery .slides img {
    height: 220px;
  }
  .teasure-gallery .flex-control-thumbs {
    width: 100%;
    margin-top: 5px;
  }
  .teasure-gallery .flex-control-thumbs > li {
    width: 32%;
    margin-bottom: 0;
  }
  .teasure-gallery .flex-control-thumbs > li:nth-child(2) {
    margin: 0 6px;
  }
  .teasure-gallery .flex-control-thumbs img {
    height: 80px;
  }
  .path-media-gallery .album-list .item-list li {
    width: 30%;
    min-height: 150px;
    margin-top: 0;
  }
  .path-media-gallery .album-list .item-list li .views-field-field-image img {
    height: 80px;
  }
  .page-node-type-album .grid-photo .item-list li {
    width: 47%;
  }
  .page-node-type-album .grid-photo .item-list li .views-field-field-image img {
    height: 150px;
  }
  .page-node-type-album .list-photo .item-list li .views-field-field-image {
    width: 35%;
  }
  .page-node-type-album .list-photo .item-list li .views-field-title {
    width: 60%;
  }
  .video-frame iframe {
    height: 306px;
  }
  .page-video-gallery .item-list ul li iframe {
    height: 160px;
  }
  .page-video-gallery .item-list ul li {
    margin: 10px 0;
  }
  .page-video-gallery .item-list ul li:nth-child(odd) {
    width: 49%;
    float: left;
  }
  .page-video-gallery .item-list ul li:nth-child(even) {
    width: 49%;
    float: right;
  }
  .page-video-gallery .item-list ul li .views-field-title {
    height: 70px;
    overflow: hidden;
  }
  .breadcrumb-widget {
    margin-left: 15px;
  }
  .content-left {
    width: 225px;
  }
  .content-right {
    width: 68%;
  }
  .path-webform .main-content {
    height: 720px;
    background-size: 65%;
    background-position: bottom;
  }
  .no-shadow {
    background-size: 70%;
    padding-top: 280px;
  }
  .socialwalltab ul li {
    width: 49%;
    margin: 0;
    margin-bottom: 5px;
  }
  .socialwalltab ul li a {
    display: block;
  }
  .socialwalltab ul li:last-child {
    float: none;
  }
  .page-node-2237 .views-row,
  .page-node-2295 .quicktabs-tabpage .views-row,
  .page-node-2296 .quicktabs-tabpage .views-row,
  .page-node-2297 .quicktabs-tabpage .views-row {
    width: 100%;
    margin: 0;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .page-node-2237 .innerpage-wrapper h1,
  .page-node-2295 .innerpage-wrapper h1,
  .page-node-2296 .innerpage-wrapper h1,
  .page-node-2297 .innerpage-wrapper h1 {
    font-size: 130%;
    width: 100%;
    float: left;
    margin: 0;
  }
  .page-node-2237 ul.quicktabs-tabs li a,
  .page-node-2295 ul.quicktabs-tabs li a,
  .page-node-2296 ul.quicktabs-tabs li a,
  .page-node-2297 ul.quicktabs-tabs li a {
    padding: 5px 10px 5px 0;
  }
  .socialwalltab ul li:last-child a {
    border-radius: 0;
  }
  .eminent-personalities > .views-row {
    width: 100%;
  }
  .eminent-personalities > .views-row {
    width: 100%;
  }
  .path-all-citizen-services-list .all-citizen-services .item-list ul li {
    margin: 8px 0;
  }
  .path-all-citizen-services-list
    .all-citizen-services
    .item-list
    ul
    li:nth-child(odd) {
    width: 49%;
    float: left;
  }
  .path-all-citizen-services-list
    .all-citizen-services
    .item-list
    ul
    li:nth-child(even) {
    width: 49%;
    float: right;
  }
  .path-all-citizen-services-list
    .views-exposed-form
    .form-actions
    input[value='Apply'] {
    margin: 0;
  }
  .path-all-citizen-services-list .views-exposed-form {
    min-height: 100px;
  }
  .path-all-mobile-apps-list .all-mobile-apps-category .item-list ul li {
    margin: 8px 0;
  }
  .path-all-mobile-apps-list
    .all-mobile-apps-category
    .item-list
    ul
    li:nth-child(odd) {
    width: 49%;
    float: left;
  }
  .path-all-mobile-apps-list
    .all-mobile-apps-category
    .item-list
    ul
    li:nth-child(even) {
    width: 49%;
    float: right;
  }
  .path-all-mobile-apps-list
    .views-exposed-form
    .form-actions
    input[value='Apply'] {
    margin: 0;
  }
  .path-all-mobile-apps-list .views-exposed-form {
    min-height: 100px;
  }
  .path-webform .main-content {
    height: 720px;
    background-size: 43%;
    background-position: 100% 12%;
  }
  .path-_t-photo-gallery ._tgallerylist .item-list ul {
    display: block;
  }
  .path-_t-photo-gallery ._tgallerylist .item-list ul li {
    margin: 5px 10px 4px 0;
    width: 31.3%;
    float: left;
  }
  .path-_t-photo-gallery ._tgallerylist .item-list ul li img {
    height: 160px;
    width: 100%;
  }
  .path-_t-video-gallery ._tvideogall .item-list ul {
    display: block;
  }
  .path-_t-video-gallery ._tvideogall .item-list ul li {
    margin: 0 0 3px;
  }
  .path-_t-video-gallery ._tvideogall .item-list ul li:nth-child(odd) {
    width: 49%;
    float: left;
  }
  .path-_t-video-gallery ._tvideogall .item-list ul li:nth-child(even) {
    width: 49%;
    float: right;
  }
  .block-views-blockstate-portal-twitter-facebook-scripts-block-1
    .views-row
    .views-field {
    width: 100%;
  }
  .page-node-15635 .content-left {
    min-height: 45vh;
    height: 45vh;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .path-_t-photo-gallery ._tgallerylist .item-list ul li img {
    height: 120px;
  }
  body.path-user .content-right {
    width: 60%;
  }
  body.path-user .content-right .logo-widget {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .logo-widget .odisha-logo img {
    max-width: 160px;
  }
  .modalconent {
    width: 90%;
  }
  .logo-widget {
    width: 180px;
  }
  .logo-widget img {
    padding: 7px 0;
  }
  .modal-search {
    right: 32.2%;
  }
  .swf_language ul.links {
    right: 8%;
  }
  .navigation.sticky.fixed .minister > a:nth-child(1) {
    padding-top: 21px;
  }
  .fixed .minister {
    right: -15px;
  }
  .fixed .we-mega-menu-submenu {
    top: 62px;
  }
  .banner-slider .slides li > img,
  .advertisement-blog img,
  .banner-widget {
    height: 40vh;
  }
  .dark-bg {
    display: block;
    float: left;
    width: 100%;
  }
  .main-news .flex1-child {
    width: 49%;
    float: left;
    margin-bottom: 15px;
    margin-right: 10px;
  }
  .main-news .flex1-child:nth-child(even) {
    margin-right: 0;
  }
  .news-item img {
    height: 120px;
  }
  .flex1-child + .flex1-child {
    margin: 0;
  }
  .main-news .flex1-child:nth-child(2),
  .main-news .flex1-child:nth-child(3) {
    margin: 0 2px;
  }
  .main-news .flex1-child:nth-child(2),
  .main-news .flex1-child:nth-child(4) {
    float: none;
  }
  .content-item ul li > a,
  .news-item a {
    font-size: 13px;
  }
  .col-1-5 {
    width: 25%;
  }
  .teasure-gallery .slides img {
    height: 280px;
  }
  .teasure-gallery .flex-control-thumbs > li:nth-child(2) {
    margin-left: 7px;
  }
  .teasure-gallery .flex-control-thumbs > li:last-child {
    float: right;
  }
  .teasure-gallery .flex-control-thumbs img {
    height: 100px;
  }
  .video-frame iframe {
    height: 385px;
  }
  .path-media-gallery .album-list .item-list li {
    width: 30%;
    min-height: 150px;
    margin-top: 0;
  }
  .path-media-gallery .album-list .item-list li .views-field-field-image img {
    height: 80px;
  }
  .page-node-type-album .grid-photo .item-list li {
    width: 31%;
  }
  .page-node-type-album .grid-photo .item-list li .views-field-field-image img {
    height: 150px;
  }
  .page-node-type-album .list-photo .item-list li .views-field-field-image {
    width: 30%;
  }
  .page-node-type-album .list-photo .item-list li .views-field-title {
    width: 65%;
  }
  .content-right {
    width: 73%;
  }
  .no-shadow {
    background-size: 40%;
    padding: 0;
  }
  .path-webform form.webform-submission-contact-form {
    max-width: 400px;
  }
  .path-webform form.webform-submission-contact-form {
    height: auto;
    background-position: 85% 50%;
    background-size: 40%;
  }
  .socialwalltab ul li {
    width: 32%;
    margin: 0;
    margin-bottom: 5px;
  }
  .socialwalltab ul li a {
    display: block;
  }
  .socialwalltab ul li:last-child {
    float: none;
  }
  .page-node-2237 .views-row,
  .page-node-2295 .quicktabs-tabpage .views-row,
  .page-node-2296 .quicktabs-tabpage .views-row,
  .page-node-2297 .quicktabs-tabpage .views-row {
    width: 49%;
    margin: 0;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .page-node-2297 .quicktabs-tabpage .views-row:nth-child(odd) {
    float: left;
  }
  .page-node-2297 .quicktabs-tabpage .views-row:nth-child(even) {
    float: right;
  }
  .page-node-2296 .quicktabs-tabpage .views-row:nth-child(odd) {
    float: left;
  }
  .page-node-2296 .quicktabs-tabpage .views-row:nth-child(even) {
    float: right;
  }
  .page-node-2295 .quicktabs-tabpage .views-row:nth-child(odd) {
    float: left;
  }
  .page-node-2295 .quicktabs-tabpage .views-row:nth-child(even) {
    float: right;
  }
  .page-node-2237 .quicktabs-tabpage .views-row {
    margin-right: 0;
  }
  .page-node-2237 .quicktabs-tabpage .views-row:nth-child(odd) {
    float: left;
  }
  .page-node-2237 .quicktabs-tabpage .views-row:nth-child(even) {
    float: right;
  }
  .page-node-2237 .innerpage-wrapper h1,
  .page-node-2295 .innerpage-wrapper h1,
  .page-node-2296 .innerpage-wrapper h1,
  .page-node-2297 .innerpage-wrapper h1 {
    font-size: 130%;
    width: 100%;
    float: left;
    margin: 0;
  }
  .page-node-2237 ul.quicktabs-tabs li a,
  .page-node-2295 ul.quicktabs-tabs li a,
  .page-node-2296 ul.quicktabs-tabs li a,
  .page-node-2297 ul.quicktabs-tabs li a {
    padding: 5px 10px 5px 0;
  }
  .socialwalltab ul li:last-child a {
    border-radius: 0;
  }
  .page-node-2296 .quicktabs-tabpage .socialAreat_tweet .te_feeds {
    min-height: 150px;
  }
  .eminent-personalities > .views-row {
    width: 100%;
    margin: 0 10px 70px;
  }
  .eminent-personalities > .views-row {
    width: 100%;
    margin: 0 10px 70px;
  }
  .path-_t-photo-gallery ._tgallerylist .item-list ul li img {
    height: 150px;
  }
  .hidden-treasure .teasure-gallery .treasure-gallery-card {
    width: 219.333px;
    margin-right: 10px;
  }
}

@media (min-width: 350px) and (max-width: 767px) {
  .modalconent {
    width: 90%;
  }
  .navigation .minister a:nth-child(1) {
    margin-top: 32px;
  }
  .navigation .minister {
    right: 0;
  }
  .navigation .minister img {
    width: 70px;
  }
  .fixed .minister {
    width: 38%;
  }
  .navigation .minister a {
    padding-top: 25px;
  }
  .advertisement-blog img {
    width: auto;
  }
  .five-t-logo {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
    background-color: #3b96c3;
  }
  .news-runner {
    position: static;
    height: auto;
  }
  .banner-widget .flex-direction-nav .flex-prev {
    left: 15px;
    top: 60%;
  }
  .banner-widget .flex-direction-nav .flex-next {
    right: 15px;
    top: 60%;
  }
  .banner-slider .slides li > img,
  .banner-widget {
    height: auto;
    display: block;
  }
  .sec-second {
    width: 100%;
    display: table;
  }
  .sec-second ul {
    width: 100%;
    margin: 5px 0;
  }
  .five-t-sec {
    background: none;
    display: block;
  }
  .five-t-listing {
    display: block;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(-0);
  }
  .sec-first {
    width: 100%;
    padding-bottom: 15px;
  }
  .sec-second {
    width: 100%;
    background-color: #033454;
    padding: 10px 0;
  }
  .five-t-listing li {
    min-width: 18%;
  }
  .sec-second ul li a {
    font-size: 11px;
  }
  .sec-second ul li {
    width: 33%;
    float: left;
    box-sizing: border-box;
    margin: 0;
    margin-bottom: 20px;
  }
  .or .sec-second ul li {
    margin: 0;
  }
  .service-content {
    padding-top: 0;
  }
  .flex1-child + .flex1-child {
    margin: 0;
  }
  .main-news .flex1-child {
    margin-bottom: 15px;
  }
  .dark-bg {
    display: block;
    float: left;
    width: 100%;
  }
  .main-news .flex1-child {
    width: 49%;
    float: left;
  }
  .main-news .flex1-child:nth-child(2),
  .main-news .flex1-child:nth-child(4) {
    float: right;
  }
  .award-section .col-1-5 {
    width: 100%;
  }
  .hidden-treasure h2 {
    font-size: 25px;
    clear: both;
    padding: 20px 0;
  }
  .teasure-gallery ul.slides {
    width: 100%;
  }
  .hidden-treasure .teasure-gallery .treasure-gallery-card {
    width: 100%;
    margin: 5px 0;
  }
  #sec3 .sec3h1,
  .sec3h {
    width: 100%;
    height: 180px;
  }
  .award-section .sec3h2 {
    width: 100%;
  }
  #sec3 .sec3h2,
  #sec3 .sec3h4 {
    width: 100%;
    height: 180px;
  }
  .odisha-profile-area ul {
    width: 33%;
    margin-bottom: 10px;
  }
  .administrative ul li a {
    padding: 5px 10px;
  }
  .odisha-menu ul li {
    width: 33%;
  }
  .teasure-gallery .slides img {
    height: 300px;
  }
  .teasure-gallery .flex-control-thumbs {
    width: 100%;
    margin: 5px 0;
  }
  .teasure-gallery .flex-control-thumbs > li {
    width: 32%;
  }
  .teasure-gallery .flex-control-thumbs > li:last-child {
    float: right;
  }
  .teasure-gallery .flex-control-thumbs > li:nth-child(2) {
    margin-left: 10px;
  }
  .teasure-gallery .flex-control-thumbs img {
    height: 100px;
  }
  .video-frame iframe {
    height: 300px;
  }
  .gallery-heading > header {
    margin-top: 15px;
  }
  .page-node-type-state-eminent-personalities
    .content
    .views-field-field-image {
    width: 100%;
    float: left;
  }
  .page-node-type-state-eminent-personalities
    .content
    .views-field-field-image
    img {
    width: 100%;
    height: 250px;
  }
  .page-node-type-state-eminent-personalities .content .views-field-body {
    width: 100%;
    float: left;
  }
  .content-right .container {
    padding: 0;
  }
  .breadcrumb-widget {
    margin-left: 10px;
  }
  .no-shadow {
    background-size: 66%;
  }
  .content-left {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  .content-right {
    width: 100%;
    margin-left: 0;
  }
  .no-shadow {
    background-size: 100%;
    padding-top: 280px;
  }
  .path-webform .main-content {
    background-size: 42%;
    background-position: 100% 10%;
  }
  .path-webform .js-form-item .form-text,
  .path-webform .js-form-item .form-email,
  .path-webform .js-form-item textarea {
    width: 100%;
  }
  .views-exposed-form {
    display: block;
    min-height: 200px;
  }
  .views-exposed-form .form-item {
    width: 50%;
    float: left;
    margin-bottom: 7px;
  }
  .views-exposed-form .form-actions {
    display: block;
  }
  .views-exposed-form .form-actions input[value='Apply'] {
    margin: 5px 0;
  }
  .path-media-gallery .album-list .item-list li {
    width: 32%;
    float: left;
    margin-right: 7px;
    margin-bottom: 10px;
    height: 160px;
  }
  .path-media-gallery .album-list .item-list li .views-field-field-image img {
    height: 100px;
  }
  .page-node-type-album .grid-photo .item-list ul {
    float: left;
    padding: 0;
  }
  .page-node-type-album .grid-photo .item-list ul li {
    width: 32%;
    margin-right: 2px;
    margin-bottom: 2px;
  }
  .page-node-type-album .grid-photo .item-list li .views-field-field-image img {
    height: 120px;
    width: 100%;
  }
  .page-node-type-album .grid-photo .item-list li .views-field-title {
    line-height: normal;
    font-size: 12px;
  }
  .page-node-type-album .list-photo .item-list li .views-field-field-image {
    width: 100%;
  }
  .page-node-type-album .list-photo .item-list li .views-field-field-image img {
    height: 200px;
  }
  .page-video-gallery .item-list ul li {
    margin: 10px 0;
  }
  .page-video-gallery .item-list ul li:nth-child(odd) {
    width: 49%;
    float: left;
  }
  .page-video-gallery .item-list ul li:nth-child(even) {
    width: 49%;
    float: right;
  }
  .page-video-gallery .item-list ul li .views-field-title {
    height: 70px;
    overflow: hidden;
  }
  .socialwalltab ul li {
    width: 49%;
    margin: 0;
    margin-bottom: 5px;
  }
  .socialwalltab ul li a {
    display: block;
  }
  .socialwalltab ul li:last-child {
    float: none;
  }
  .page-node-2237 .views-row,
  .page-node-2295 .quicktabs-tabpage .views-row,
  .page-node-2296 .quicktabs-tabpage .views-row,
  .page-node-2297 .quicktabs-tabpage .views-row {
    width: 100%;
    margin: 0;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .page-node-2237 .innerpage-wrapper h1,
  .page-node-2295 .innerpage-wrapper h1,
  .page-node-2296 .innerpage-wrapper h1,
  .page-node-2297 .innerpage-wrapper h1 {
    font-size: 130%;
    width: 100%;
    float: left;
  }
  .page-node-2237 ul.quicktabs-tabs li a,
  .page-node-2295 ul.quicktabs-tabs li a,
  .page-node-2296 ul.quicktabs-tabs li a,
  .page-node-2297 ul.quicktabs-tabs li a {
    padding: 5px 10px 5px 0;
  }
  .socialwalltab ul li:last-child a {
    border-radius: 0;
  }
  .eminent-personalities > .views-row {
    width: 100%;
  }
  .eminent-personalities > .views-row {
    width: 100%;
  }
  .page-node-type-flash-news .content .views-row .views-field {
    width: 50%;
    margin-bottom: 10px;
  }
  .path-all-mobile-apps-list .all-mobile-apps-category .item-list ul li {
    margin: 8px 0;
  }
  .path-all-mobile-apps-list
    .all-mobile-apps-category
    .item-list
    ul
    li:nth-child(odd) {
    width: 49%;
    float: left;
  }
  .path-all-mobile-apps-list
    .all-mobile-apps-category
    .item-list
    ul
    li:nth-child(even) {
    width: 49%;
    float: right;
  }
  .path-all-mobile-apps-list
    .all-mobile-apps-category
    .form-actions
    input[value='Apply'] {
    margin: 0;
  }
  .path-all-mobile-apps-list .views-exposed-form {
    min-height: 100px;
  }
  .path-all-citizen-services-list .all-citizen-services .item-list ul li {
    margin: 8px 0;
  }
  .path-all-citizen-services-list
    .all-citizen-services
    .item-list
    ul
    li:nth-child(odd) {
    width: 49%;
    float: left;
  }
  .path-all-citizen-services-list
    .all-citizen-services
    .item-list
    ul
    li:nth-child(even) {
    width: 49%;
    float: right;
  }
  .path-all-citizen-services-list
    .views-exposed-form
    .form-actions
    input[value='Apply'] {
    margin: 0;
  }
  .path-all-citizen-services-list .views-exposed-form {
    min-height: 100px;
  }
  .path-vision-5t .inner-content {
    margin-bottom: 20px;
  }
  .news-info {
    position: static;
    width: 100%;
    min-height: 330px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .news-feed {
    margin-top: 15px;
  }
  .news-feed .news-bg {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 150px;
  }
  .path-_t-photo-gallery ._tgallerylist .item-list ul {
    display: block;
  }
  .path-_t-photo-gallery ._tgallerylist .item-list ul li {
    margin: 5px 0 4px;
  }
  .path-_t-photo-gallery ._tgallerylist .item-list ul li:nth-child(odd) {
    width: 49%;
    float: left;
  }
  .path-_t-photo-gallery ._tgallerylist .item-list ul li:nth-child(even) {
    width: 49%;
    float: right;
  }
  .path-_t-photo-gallery ._tgallerylist .item-list ul li img {
    height: 180px;
    width: 100%;
  }
  .path-_t-video-gallery ._tvideogall .item-list ul {
    display: block;
  }
  .path-_t-video-gallery ._tvideogall .item-list ul li {
    margin: 0 0 3px;
  }
  .path-_t-video-gallery ._tvideogall .item-list ul li:nth-child(odd) {
    width: 49%;
    float: left;
  }
  .path-_t-video-gallery ._tvideogall .item-list ul li:nth-child(even) {
    width: 49%;
    float: right;
  }
  .carousel-wrapper .flex-pauseplay {
    display: block;
  }
  body.path-user .content-right {
    width: 60%;
  }
  body.path-user .content-right .logo-widget {
    width: 100%;
  }
  .block-views-blockstate-portal-twitter-facebook-scripts-block-1
    .views-row
    .views-field {
    width: 100%;
  }
  .page-node-15635 .content-left {
    min-height: 45vh;
    height: 45vh;
  }
  .bottom-footer a {
    display: block;
  }
  .footer-inline-menu ul {
    padding-left: 7px;
  }
  .footer-inline-menu ul li {
    width: auto;
    padding: 3px 10px;
  }
  .footer-inline-menu ul li a {
    display: block;
  }
  .bottom-footer a {
    display: block;
  }
  .top-link + .container .col-lg-3:nth-child(1) ul li,
  .top-link + .container .col-lg-3:nth-child(2) ul li {
    width: 50%;
    float: left;
  }
  .main-footer .col-lg-3 ul li {
    width: 50%;
    float: left;
  }
  .footer-inline-menu div.lstupd {
    top: -25px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .modalconent {
    width: 90%;
  }
  .marquee-text .twitter_img {
    margin-right: 0;
    float: left;
    width: 84px;
  }
  .box1 {
    max-width: 260px;
  }
  .marquee-text #example-scroll_change {
    width: 68%;
    padding-right: 5px;
  }
  .navigation .minister {
    right: 0;
  }
  .navigation .minister img {
    width: 60px;
  }
  .navigation .minister a {
    padding-top: 25px;
    font-size: 80%;
  }
  .navigation .minister a:nth-child(1) {
    margin-top: 20px;
  }
  .navigation.sticky.fixed .minister > a:nth-child(1) {
    position: absolute;
    padding-top: 25px;
  }
  .navigation.sticky.fixed .minister > a:nth-child(2) {
    float: right;
  }
  .swf_language ul.links {
    right: 16%;
    z-index: 9;
  }
  body.or .swf_language ul.links {
    right: 13%;
  }
  .five-t-logo {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
    background-color: #3b96c3;
  }
  .news-runner {
    position: static;
    height: auto;
  }
  .banner-widget .flex-direction-nav .flex-prev {
    left: 15px;
    top: 60%;
  }
  .banner-widget .flex-direction-nav .flex-next {
    right: 15px;
    top: 60%;
  }
  .banner-slider .slides li > img,
  .banner-widget {
    height: auto;
    display: block;
  }
  .sec-second {
    width: 100%;
    display: table-cell;
    padding: 10px 0;
  }
  .sec-second ul {
    width: 100%;
    margin: 5px 0;
  }
  .five-t-sec {
    background: none;
    display: block;
  }
  .five-t-listing {
    display: block;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(-0);
  }
  .sec-first {
    width: 100%;
    padding-bottom: 15px;
  }
  .sec-second {
    width: 37rem;
    background-color: #033454;
  }
  .five-t-listing li {
    min-width: 29%;
  }
  .sec-second ul li a {
    font-size: 11px;
  }
  .sec-second ul li {
    width: 33%;
    float: left;
    box-sizing: border-box;
    margin: 0;
    margin-bottom: 20px;
    min-height: 80px;
  }
  .or .sec-second ul li {
    margin: 0;
  }
  .service-content {
    padding-top: 0;
  }
  .flex1-child + .flex1-child {
    margin: 0;
  }
  .main-news .flex1-child {
    margin-bottom: 15px;
  }
  .hidden-treasure h2 {
    font-size: 25px;
    clear: both;
    padding: 20px 0;
  }
  .teasure-gallery ul.slides {
    width: 100%;
  }
  .hidden-treasure .teasure-gallery .treasure-gallery-card {
    width: 100%;
    margin: 5px 0;
  }
  #sec3 .sec3h1,
  .sec3h {
    width: 100%;
    height: 180px;
  }
  .award-section .sec3h2 {
    width: 100%;
  }
  #sec3 .sec3h2,
  #sec3 .sec3h4 {
    width: 100%;
    height: 180px;
  }
  .col-1-5 {
    width: 100%;
  }
}
