.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    border-radius: 0 !important;
    background-color: transparent !important;
    border-bottom: 2px solid #1a84c4 !important;
    color: rgb(0, 0, 0) !important; 
}
.nav-pills .nav-link{
    border-radius: 0 !important;
    width: 100% !important;
    background-color: transparent !important;
    border-color: #781ac4 !important;
    color: black !important; 
}
li.nav-item{
    width: 50% !important;
}
