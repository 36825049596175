a.dropdown-item-year {
  font-size: 15px;
  width: 7.2rem !important;
}
.dropdown-menu-year {
  width: 7.2rem !important;
  min-width: 7.2rem !important;
}
.Select-arrow-zone {
  background-color: green !important;
}

.form-select{
  background-image: url('../../assets/images/icon/down-arrow.png');
  border-radius:4px !important;
}