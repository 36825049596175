html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Roboto', sans-serif;
}

.main-slider .slick-slide {
  height: 100vh;
  position: relative;
}

.pos-left-top {
  left: 0;
  top: 18%;
}

.pos-left-bottom {
  left: 0;
  bottom: 20px;
}

.pos-right-bottom {
  right: 0;
  bottom: 0;
}
.header-text-section.col-xxl-10.col-xl-10.col-md-9 {
  margin-top: 20px;
}

@media (min-width: 1200px) {
  .main-slider .slick-slide {
    height: 750px;
  }
}
@media screen and (max-width: 1400px) {
  .light-blue {
    width: 87%;
    background-color: #e3eff9;
    font-family: 'Roboto', sans-serif;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    font-size: 30px;
    font-weight: 300;
  }
}

@media screen and (min-width: 2500px) {
  .sec-second {
    margin-left: 11%;
  }
  .pos-left-top {
    left: 0;
    top: 5%;
  }
  img.img-fluid.opams-logo.mx-auto {
    max-width: 20rem;
    max-height: 20rem;
  }
  .header-text-section.col-xxl-9.col-xl-10.col-md-10 {
    margin-top: 59px;
  }
  h1.display-3.opams-color {
    font-size: 5rem;
    color: #ff0000 !important;
    font-weight: 700;
    border-bottom: 2px solid #176a8a;
  }
  h2.display-5.opams-full-text {
    font-size: 52px;
  }
}

@media screen and (max-width: 1654px) and (min-width: 1464px) {
  .sec-second {
    margin-left: 11%;
  }
  .pos-left-top {
    left: 0;
    top: 18%;
  }
  img.img-fluid.opams-logo.mx-auto {
    max-width: 11rem;
    max-height: 11rem;
  }
  .header-text-section.col-xxl-9.col-xl-10.col-md-10 {
    margin-top: 29px;
  }
  h1.display-3.opams-color {
    font-size: 56px;
    color: #ff0000 !important;
    font-weight: 700;
    border-bottom: 2px solid #176a8a;
  }
  h2.display-5.opams-full-text {
    font-size: 31px;
  }
}

@media screen and (max-width: 2500px) and (min-width: 1464px) {
  .sec-second {
    margin-left: 11%;
  }
  .pos-left-top {
    left: 0;
    top: 5%;
  }
  img.img-fluid.opams-logo.mx-auto {
    max-width: 11rem;
    max-height: 11rem;
  }
  .header-text-section.col-xxl-9.col-xl-10.col-md-10 {
    margin-top: 29px;
  }
  h1.display-3.opams-color {
    font-size: 56px;
    color: #ff0000 !important;
    font-weight: 700;
    border-bottom: 2px solid #176a8a;
  }
  h2.display-5.opams-full-text {
    font-size: 31px;
  }
}

@media screen and (max-width: 1464px) and (min-width: 1214px) {
  .pos-left-top {
    left: 0;
    top: 8%;
  }
  img.img-fluid.opams-logo.mx-auto {
    max-width: 8rem;
    max-height: 8rem;
  }
  .header-text-section.col-xxl-9.col-xl-10.col-md-10 {
    margin-top: 11px;
  }
  h1.display-3.opams-color {
    font-size: 51px;
    color: #ff0000;
    font-weight: 700;
    border-bottom: 2px solid #176a8a;
  }
  h2.display-5.opams-full-text {
    font-size: 36px;
  }
}

@media screen and (max-width: 1214px) and (min-width: 720px) {
  .pos-left-top {
    left: 0;
    top: 5%;
  }

  img.img-fluid.opams-logo.mx-auto {
    max-width: 6rem;
    max-height: 6rem;
  }
  .header-text-section.col-xxl-9.col-xl-10.col-md-10 {
    margin-top: 0px;
  }
  h1.display-3.opams-color {
    font-size: 46px;
    color: #ff0000;
    font-weight: 700;
    border-bottom: 2px solid #176a8a;
  }
  h2.display-5.opams-full-text {
    font-size: 27px;
  }
}
@media screen and (max-width: 773px) and (min-width: 375px) {
  .pos-left-top {
    left: 0;
    top: 1%;
  }
  img.img-fluid.opams-logo.mx-auto {
    max-width: 3rem;
    max-height: 3rem;
  }
  .header-text-section.col-xxl-9.col-xl-10.col-md-10 {
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .first-col-logo.col-xxl-3.col-xl-2.col-md-2 {
    display: flex;
    justify-content: center;
  }
  h1.display-3.opams-color {
    font-size: 26px;
    color: #ff0000;
    font-weight: 600;
    border-bottom: unset;
  }
  h2.display-5.opams-full-text {
    display: none;
    font-size: 27px;
  }
}

@media screen and (min-width: 768px) {
  .light-blue {
    width: 100%;
    background-color: #e3eff9;
    font-family: 'Roboto', sans-serif;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    font-size: 30px;
    font-weight: 300;
  }
}

.slider1 {
  background-color: rgba(255, 255, 255, 0.5);
  max-width: 800px;
}

.slide-cont .img-cont {
  max-width: 166px;
}

@media (min-width: 1177px) and (max-width: 1316px) {
  .content {
    margin-left: 7rem;
  }
}

.light-blue {
  font-size: 40px;
  width: 87%;
  background-color: #e3eff9;
  font-family: 'Roboto', sans-serif;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  font-weight: 300;
}

.focal-point {
  position: relative;
}

.focal-point img {
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.orsaclogo {
  bottom: 10px;
  right: 10px;
}

.orsaclogo img {
  width: 80px;
  filter: drop-shadow(0 0 8px white);
}
.opams-logo {
  max-width: 12rem;
  max-height: 12rem;
}

.underslider {
  background-color: #033454;
}
.underslider .leftsec {
  border-bottom: 10px solid #f36051;
}
.underslider .rightsec {
  border-top: 10px solid #9724f5;
}
.hover-img {
  padding-top: 30px;
}
.hover-img span {
  font-size: 0.9em;
}
.hover-img img {
  position: absolute;
  top: -30px;
  left: 50%;
  margin-left: -37px;
  transition: transform 1s;
}
.hover-img img:hover {
  transform: rotate(45deg);
}
.righticons {
  list-style: none;
  font-size: 0.6em;
}
.righticons a {
  text-decoration: none;
  color: #fff;
}
.righticons li {
  display: inline-block;
  padding: 16px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}
.curve-canvas {
  width: 100%;
}

.linkassetscheckdam,
.linkassetsgoplus,
.linkassetsODIiS,
.linkassets4kgeo,
.linkassetsofms,
.linkassetroad,
.linkassetofaris,
.linkassetiklms {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  text-align: center;
  color: black;
  font-size: 9px;
  cursor: pointer;
  transition: all 1s;
  box-shadow: 0 0 0 1px #417fbd inset, 0 0 0 2px rgb(255 255 255 / 15%) inset,
    0 0px 0 0 #4d5bbe, 0 6px 9px 1px rgb(0 0 0 / 50%);
}

.linkassetscheckdam {
  background-image: url(../../assets/images/uslider/checkdamage.png);
}

.linkassetsgoplus {
  background-image: url(../../assets/images/uslider/goplus.png);
}
.linkassetsODIiS {
  background-image: url(../../assets/images/uslider/ODIiS.png);
}
.linkassets4kgeo {
  background-image: url(../../assets/images/uslider/4kgeo.png);
}
.linkassetsofms {
  background-image: url(../../assets/images/uslider/ofms.png);
}
.linkassetroad {
  background-image: url(../../assets/images/uslider/road.png);
}

.linkassetofaris {
  background-image: url(../../assets/images/uslider/ofaris_logo.png);
}

.linkassetiklms {
  background-image: url(../../assets/images/uslider/iklmslogo.png);
}

@media screen and (max-width: 600px) {
  .orsaclogo img {
    width: 55px;
    filter: drop-shadow(0 0 8px white);
  }
  .opams-logo {
    max-width: 100px;
    max-height: 20vh;
  }
  .display-3 {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
  .light-blue {
    background-color: #e3eff9;
    font-family: 'Roboto', sans-serif;
    box-shadow: 5px 5px 5px rgb(0 0 0 / 20%);
    font-weight: 300;
  }

  .p-3 {
    padding: 1rem !important;
    font-size: 20px;
  }
  .h2,
  h2 {
    font-size: 1rem;
  }
}

@media (min-width: 36em) {
  #menutoggle {
    padding: 0 15px 0 15px;
  }

  #menutoggle span {
    display: inline-block;
  }

  #menutoggle:before {
    margin-right: 10px;
  }
}

@media (min-width: 62em) {
  /* Large devices (desktops, 992px and up) */
  #menutoggle {
    display: none;
  }

  #nav {
    height: 45px;
    position: relative;
    z-index: 99;
    border: 0;
    margin: 0;
    border-radius: 4px;
  }

  #nav ul {
    display: block !important;
    float: left;
    width: 100%;
    height: auto;
    opacity: 1;
    margin: 4px 0;
    position: relative;
    z-index: 10;
    top: 0;
    overflow: visible;
  }

  #nav li {
    float: left;
    margin: 0 5px;
    padding: 0;
    height: 37px;
    width: auto;
    min-width: auto;
    position: relative;
    border: 0;
    overflow: visible;
  }

  #nav li:first-child {
    margin-left: 4px;
    border-left: 0;
  }

  #nav li.last {
    border-right: 0;
  }

  #nav li.right {
    float: right;
    margin-right: 4px;
    border-right: 0;
  }

  #nav li.onmobile {
    display: none;
  }

  #nav a,
  #nav span.submenu {
    float: left;
    margin: 0;
    padding: 0 12px;
    height: 36px;
    border: 0;
    color: #333;
    font-weight: normal;
    line-height: 39px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 3px;
    transition: background 0.2s linear 0s;
    cursor: pointer;
  }

  #nav span.submenu:after {
    display: none;
  }
  #nav li:hover a.submenu,
  #nav li:hover span.submenu,
  #nav span.submenu.is-active.hover {
    border-radius: 3px 3px 0 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  #nav li:hover {
    z-index: 11;
  }

  #nav ul.submenu {
    float: left;
    margin: 0;
    padding: 0;
    width: 250px;
    max-height: 0;
    position: absolute;
    opacity: 0;
    background: #455a64;
    top: 95%;
    z-index: 10;
    border-radius: 0 3px 3px 3px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    transition: max-height 0.2s linear 0s, padding 0.2s linear 0s,
      opacity 0.2s linear 0s;
    overflow: hidden;
    overflow-y: auto;
  }

  #nav li:hover ul.submenu,
  #nav ul.submenu.is-active {
    padding: 3px 0;
    left: 0;
    max-height: 360px;
    opacity: 1;
  }

  #nav ul.submenu li {
    float: none;
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    height: auto;
  }

  #nav ul.submenu a {
    float: none;
    display: block;
    margin: 0;
    padding: 7px 15px 7px 28px;
    height: auto;
    text-transform: none;
    text-align: left;
    line-height: 1.5em;
    border-radius: 0;
    border: 0;
    border-top: 1px dotted #37474f;
  }

  #nav ul.submenu li:first-child a {
    border: 0;
  }

  #nav ul.submenu li a:hover,
  #nav ul.submenu li a.is-active {
    background: #37474f;
  }
}

.container-for-naveen {
  max-width: 100% !important;
}

/* About section start */
.about-button {
    padding: 7px 22px;
    color: white;
    font-size: 13px;
    background-color: #033454;
}


.about-button:hover {
  color: #033454;
  border-color: #033454 !important;
  background: #fff !important;
}

.about-header-hide {
  display: none;
}
h5.about-header {
  font-size: 21px;
  text-transform: uppercase;
  font-family: 'Roboto';
  letter-spacing: 2px;
  color: #db2323;
}
@media (max-width: 768px) {
  .about-header-hide {
    padding: 0 28px;
    display: block;
    margin-bottom: 10px;
  }
  .ppt-first-img-about {
    display: none;
  }
  h3.odisha-public-header {
    font-size: 17px;
  }
  h5.about-header {
    font-size: 21px;
    text-transform: uppercase;
    font-family: 'Roboto';
    letter-spacing: 2px;
    color: #db2323;
  }
}
@media (max-width: 1000px) {
  .naveenJi-logo-header,
  .nav-items-header {
    display: none;
  }
}
/* About section end */
